import SocketIOClient from "socket.io-client";
import avaDefault from './assets/images/ava_default.jpg'
import imgDefault from './assets/images/img_default.jpg'

let Config = {

    // API connection local
    // host: process.env.REACT_APP_TEEMIE_HOST || "http://localhost:8012/api",
    // secret: process.env.REACT_APP_TEEMIE_SECRET || "l0hx0dk4LnJx2Cvq3NwOe7uDyd2fW6ja7kc0le",

    // API connection dev
    // host: process.env.REACT_APP_TEEMIE_HOST || "http://teemie.spiritlabs.co/api",
    // secret: process.env.REACT_APP_TEEMIE_SECRET || "d0hc9d0k5LnJx9Cvq3NwOe3uDy2fW9ja2kc0de",

    // API connection prod
    //host: process.env.REACT_APP_TEEMIE_HOST || "https://uat.teemieapp.com/api",

    host: process.env.REACT_APP_TEEMIE_HOST || "https://test.teemieapp.com/api",
    secret: process.env.REACT_APP_TEEMIE_SECRET || "p0hv6dk6LnJQx7Cvq3NwOe2uDy2fW1ja9kc0pe",
    
    // API connection test
    //host: process.env.REACT_APP_TEEMIE_HOST || "http://localhost:8012/api",
    //secret: process.env.REACT_APP_TEEMIE_SECRET || "p0hv6dk6LnJQx7Cvq3NwOe2uDy2fW1ja9kc0pe",


    tokenLocal: "",
    app_name: "",
    google_key: "",
    facebook_id: "",
    androidClientId: "",
    version:'1.0',

    setting : {},
    getToken : false,

    socket : null,
    popup : null,
    loading : null,
    currentScreen: "",
    deviceToken: null,
    isNetwork: '',
    avaDef: avaDefault,
    imageDef: imgDefault,
    firebase: {
        apiKey: 'AIzaSyAlZqK0whL4Iu76Xh4jUTUOZRN_dyduiMo',
        authDomain: 'teemie-f4a71.firebaseapp.com',
        databaseURL: 'https://teemie-f4a71-default-rtdb.firebaseio.com/',
        storageBucket: 'teemie-f4a71.appspot.com'
    }
};

const gMess = {
    SYS001:'System error!',
    SYS002:'Authentication token is required',
    SYS003:'Authentication token are not matching',
    SYS004:'Authentication token is expired',
    SYS005:'Authentication error request timeout',
    SYS006:'Update token fail',
    SYS007:'Socket error',
    SYS008:'The data is not in JSON format',
    SYS009:'The data is not in list',
    SYS010:'The data is not number',
    SYS011:'The data is unique',
    SYS012:'The database error',
    SYS500:'Server error',
    SYS404:'Page is not found.',
    SYS015:'User is not authentication. Please login again',
    SYS013:'You do not have the correct permissions. Please contact your company to change this.',
    SYS016:'You should be able to log in on both web and mobile so this error should not appear',
    US004:'Failed to log in. Please ensure your details are correct',

    US005:'',
    US006:'Email is required',
    US007:'Password is required',
    US0014:'Please verify your email before using the app',
    US014:'This account is not verified. Please check your email to continue. ',

    US0017:'This account does not exist in the system',
    US028:'Your account has been deleted',

    WW001:'Team member added successfully',
    WW002:'Team member removed successfully',

    CMP001:'You cannot add any more team members, please upgrade your plan to do this.',

    TM012:'This user has been invited already',
    TM013:'This user has already been registered for another company',

    MSA002:"Please select a profile picture",
    MSA003:"Please select a company logo",

    TRAGCODE001:'You are not the owner or administrator of a team',
    SAVELOGO:'Please save logo before going to the next step!',
    SAVEAVA:'Please save profile picture before going to the next step!',
    NOTTEAM:'To access this feature please ensure you have at least one active team',
    CREATEROSTERADMIN:"You can't create roster for the admin",
    PASSWORDFORMAT:"Passwords must have at least 8 characters. 1 uppercase, 1 lowercase, 1 number, 1 special character.",
    PASSWORDCONFIRM:"Passwords do not Match.",
    PASSWORDREQUIRED:"Password is required.",
    NAMELENGTH:"Name must be in 20 characters.",
    SOCKETWAIT:"Name must be in 20 characters."
};
Config.gMess = gMess;

Config.getSetting = function (name) {
    if(!Config.setting){
        return null;
    }

    for(let i = 0; i < Config.setting.length; i++)
    {
        const s = Config.setting[i];

        if(s && s.name === name) {
            return s.value;
        }
    }

    return null;
};

Config.loadSocket = function(){
    if(!Config.tokenLocal || !Config.tokenLocal.token) return;
    const token = Config.tokenLocal.token;
    const socketOptions = {
        transports: ["polling"],
        reconnection: true
    };
    const io = SocketIOClient(Config.host.replace('/api', '') + '?__sails_io_sdk_version=0.13.6&x-access-token=' + token, socketOptions);
    io.on('connect', () => {
        try {
            Config.socket = io;
        } catch (error) { }
    })
};

Config.alertMess = function (data) {
    let err = ['SYS001','SYS002','SYS003','SYS004','SYS015','SYS016','TEAM001'];
    if(err.indexOf(data.code) !== -1 && window.location.href.indexOf('/login') === -1) {
        setTimeout(()=>{
            localStorage.removeItem('LOGIN');
            localStorage.removeItem('TOKEN');
            Config.tokenLocal = null;
            window.location.href = '/login';
        },3000);
    }
    for(let key in Config.gMess) {
        if(key === data.code && Config.gMess[key]){
            return Config.gMess[key];
        }
    }
    return data.message;
};

Config.isJSON = function(str){
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export default Config;
