import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//import action
import * as userActions from "../../redux/user/user_actions";
import * as companyActions from "../../redux/company/company_actions";
import * as mainActions from "../../redux/main/main_actions";
import * as teamActions from "../../redux/team/team_actions";
import * as eventActions from "../../redux/event/event_actions";
import * as shiftActions from "../../redux/shift/shift_actions";
import * as rosterActions from "../../redux/roster/roster_actions";

//import css
import '../register/register.css'
import './roster.css'
import '../my-team/my-team.css'
import '../my-team-detail/my-team-detail.css'
import '../libs/date-time-picker/date-time-picker.css';

//import component
import { Button, Image, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SearchBar from "../my-team-detail/search-bar";
import { Link, browserHistory } from "react-router";
import Carousel from 'nuka-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import moment from "moment";
import Config from "../../config";
import PrintButton from "./PrintButton";
import ReactLoading from "react-loading";
import { CSVLink } from "react-csv";
import Api from '../../services/api.js';
import CForm from "../libs/cform/cform";

import EllipsisText from "react-ellipsis-text";
import { RangeStepInput } from 'react-range-step-input';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import Select from 'react-select';
import Header from '../header/header.js';
import { Title } from 'react-bootstrap/lib/Modal.js';
import { map } from 'jquery';
import { indexOf, result } from 'lodash';
import Loader from '../loader/loader';
import Loaderview from './loaderview.js';

class Roster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idTeam: null,
            week: 0,
            // month:0,
            arrDayMonth: [],
            flagTab: 1,
            flagCreate: false,
            dataInfo: null,
            flagConfirm: false,
            flagActivateClock: false,
            dataCreateStaff: [],
            shiftList: [],
            rosterList: [],
            memberList: null,
            onlyDay: moment().format(),
            month: moment().month(), 
            arrMemberCreate: null,
            isLoadingExport: false,
            arrDay: [],
            fixedValue: 100,
            headerRosterScroll: false,
            isClockEnabled: false,
            address: '',
            radius: 10,
            lat: 0,
            lng: 0,
            clockList: [],
            isRemoveMember: false,
            isCreateNewRosterShift:false,
            isClockedOut: false,
            isloading: true,
            bookHoliday:null,
            isHoliday:false,
            isReqHolidayData:null,
            holidayUserData:null,
            isBookedHoliday:false,
            isRejectedHoliday:false,
            isholidayRequestProcessed:false,
            isApprovedBy:'',
            dataChoose:true,
            selectedTeamMemberData:{},
            toggleUserList : false,
            dropdownclose:false,
            scrolled : false,
           searchValue: '',
           clockActivateStep:0,
           toggleTeamSelection:true,
           isTeamOpened:false,
        };
        this.dataFilter = {
            limit: 1000,
            skip: 0,
        };

        this.divRef = React.createRef();
       
                  
    }
  
    componentDidMount() {
        let teamId = window.location.search.split('?tid=');
        teamId = teamId && teamId.length > 1 ? teamId[1] : null;
        const rosterPageStateString = localStorage.getItem('ROSTERPAGESTATE'); 

        if (rosterPageStateString) {
            const rosterPageState = JSON.parse(rosterPageStateString);

            let ros_id = rosterPageState.rosters.data;
            let staff_id = rosterPageState.rosters.senderId;
            let team_id=rosterPageState.rosters.teamId;
            let ros_type = rosterPageState.holiday;
            console.log("team id",team_id)
            if(ros_type=== "NEW_HOLIDAY"){
            this.setState({ 
                bookHoliday: rosterPageState,
                isHoliday:true,
                isloading: true,
                });

                this.setState({
                    idTeam: team_id
                },()=>{
                    this.props.teamActions.saveTeam(team_id);
                    this.changeDropDownTeam({id:team_id});
                    console.log("mmmmm",this.state.idTeam)
                });
                
            let condition = JSON.stringify({"team": team_id,"user":staff_id,"status":"ACTIVE"});
             this.props.teamActions.searchMemberTeamList({condition:condition},(err, data)=> {
                if(err){
                    console.error("Error fetching team:", err);
                   this.setState({
                          isloading: false
                     });
                    
                }
                else{
                    this.setState({ 
                        holidayUserData:data[0],
                        isloading: false,
                        });
                    }
                
            });
            console.log("bookHoliday state new",this.state.bookHoliday,rosterPageState);
            
        }
        }; 
        this.props.teamActions.saveTeam(teamId, () => {
            this.getArrDay();
        });
        window.addEventListener('scroll', this.handleScroll);
        this.divRef.current.addEventListener('scroll', this.handleDivScroll , true);
    }

    componentWillUnmount() {
        this.divRef.current.removeEventListener('scroll', this.handleDivScroll , true);
      }

    componentWillMount() {
        // window.removeEventListener('scroll', this.handleScroll);
    }

    handleDivScroll = () => {
      console.log("scroll top :",this.divRef.current.scrollTop);
        if (this.divRef.current.scrollTop > 20) {
          this.setState({ scrolled: true });
        } else {
          this.setState({ scrolled: false });
        }
      };

    handleScroll = () => {
        
        let scrollTop = window.pageYOffset;
        console.log("scroll top check:",scrollTop);
        if (scrollTop > 5) {
            this.setState({
                fixedValue: 80,
                headerRosterScroll: true
            });
        } else {
            this.setState({
                fixedValue: 100,
                headerRosterScroll: false
            });
        }
        if (this.divRef.current) {
            const scrollTop = this.divRef.current.getBoundingClientRect().top + window.pageYOffset;
           console.log("scroll top :",scrollTop);
          }

    }

    getId = () => {
        let location = window.location.href;
        if (location.indexOf('?user=')) {
            let memberId = window.location.search.split('?user=');
            memberId = memberId[memberId.length - 1].split("&tid=");
            memberId = memberId[0];
            memberId = memberId || null;
            return memberId;
        }
        else {
            return null;
        }
    };

    init = async() => {
        let dataFilter = this.dataFilter;
        console.log("***********************************",this.state.flagTab);
        if (this.state.flagTab === 1) {
            dataFilter.timeFrom = moment(this.state.arrDay[0]).startOf('day').toISOString();
            dataFilter.timeTo = moment(this.state.arrDay[this.state.arrDay.length - 1]).endOf('day').toISOString();
        }
        else if (this.state.flagTab === 0) {
            dataFilter.timeFrom = moment(this.state.onlyDay).startOf('day').toISOString();
            dataFilter.timeTo = moment(this.state.onlyDay).endOf('day').toISOString();
        }
        else if(this.state.flagTab === 2){
            
            dataFilter.timeFrom = moment(this.state.arrDayMonth).startOf('month').startOf('day').toISOString();
            dataFilter.timeTo = moment(this.state.arrDayMonth).endOf('month').endOf('day').toISOString();
            
        }
        else {
            dataFilter.timeFrom = moment(this.state.arrDay[0]).startOf('day').toISOString();
            dataFilter.timeTo = moment(this.state.arrDay[this.state.arrDay.length - 1]).endOf('day').toISOString();
        }
        // dataFilter.timeFrom = this.state.arrDay[0];
        // dataFilter.timeTo = this.state.arrDay[this.state.arrDay.length - 1];
        let MemberId = this.getId();
        if (MemberId) dataFilter.staff = MemberId;
        this.setState({
            isloading:true
        });
        console.log("roasterList dataFilter",dataFilter);
        await this.props.rosterActions.getListRoster(dataFilter, (err, data) => {
            console.log("roasterList data",data);
            if (err) {
                // Config.popup.show('INFO',err.message);
                this.setState({
                    rosterList: [],
                    isloading: false
                });
            }
            else {
                this.setState({
                    rosterList: data,
                    isloading: false,
                });
            }
        });
        console.log("roasterList",this.state.rosterList);
        await this.getShift();

        await this.props.teamActions.getTeam({ id: this.state.idTeam }, (err, data) => {
            if (err) {
                this.setState({
                    isClockEnabled: false
                });
            }
            else {
                this.setState({
                    isClockEnabled: data.is_clock_enabled,
                    address: data.business_clock_in_address,
                    // radius: data.business_radius,
                    lat: data.business_clock_lat,
                    lng: data.business_clock_long,
                    flagActivateClock:false,
                    clockActivateStep:0
                });
            }
        })
    };

    getArrDay = (number) => {
        let week = this.state.week;
        let d = moment().startOf('week');
        let arr = [];
        d.add((week * 7), 'days');
        // let month = parseInt(moment().format('MM'),10);
        for (let i = 1; i <= 7; i++) {
            let day = d.add(1, 'days');
            arr.push(day.format());
        }
        if (arr.length <= 0 && number) {
            this.setState({
                week: parseInt(this.state.week, 10) - number
            });
            // return;
        }
        this.setState({
            arrDay: arr,
        }, () => {
            this.init();
        })
    };

    getMonthData = (number = 0) => {
        let { month } = this.state;
        let d = moment().month(month).startOf('month');
        let arr = [];
    
        for (let i = 0; i < d.length; i++) {
          arr.push(d.format());
          d.add(1, 'day');
        }
    
        if (arr.length <= 0 && number) {
          this.setState({ month: (parseInt(this.state.month) - number) }, () => {
            this.getMonthData(number);
          });
        } else {
          this.setState({ arrDayMonth: arr }, () => {
            this.init();
          });
        }
      }
    
      changeMonth=(month)=>{
        console.log("changemonth",month);
        this.setState({arrDayMonth:month,rosterList:[]},()=>{
            this.init();
        });
      }
    

    _getMemberTeamList = async() => {
        this.props.teamActions.getMemberTeamList({ team: this.state.idTeam }, (err, data) => {
            if (err) {
                // Config.popup.show('INFO',err.message);
                this.setState({
                    memberList: []
                })
            }
            else {
                // that._goScreenTeamDetai();
                this.setState({
                    memberList: data
                })
            }
        });
    };

    changeTab = (idx) => {
        
        this.setState({
            flagTab: idx,
            dataCreateStaff: [],
            rosterList: [],
            onlyDay: moment().format()
        }, () => {
            this.init();
            let memberList = this.state.memberList;
            let firstActiveMember='';
            for (let i = 0; i < memberList.length; i++) {
                if (memberList[i].status === "ACTIVE") {
                    firstActiveMember = {
                        label: memberList[i].user.fullName,
                        value: memberList[i].id,
                        user_id: memberList[i].id,
                        user: memberList[i].user,
                    };
                    break; 
                }
            }
            this.monthValidation(firstActiveMember,true)
        })
    };

    changeDropDownTeam = (data) => {
        let location = window.location.href;
        if (location.indexOf('?tid=')) {
            browserHistory.push('/roster');
        }
        if (this.refs['WeekView']) this.refs['WeekView'].clearDataChoose();
        this.setState({
            dataCreateStaff: [],
            idTeam: data.id,
            dataChoose:{}
        }, async() => {
            console.log("changeDropDownTeam1");
            await this._getMemberTeamList();
            console.log("changeDropDownTeam2");
            await this.init();
            console.log("changeDropDownTeam3");
            await this.getShift();
            this.setState({
                toggleTeamSelection:false
            });

            let memberList = this.state.memberList;
            let firstActiveMember='';
            for (let i = 0; i < memberList.length; i++) {
                if (memberList[i].status === "ACTIVE") {
                    firstActiveMember = {
                        label: memberList[i].user.fullName,
                        value: memberList[i].id,
                        user_id: memberList[i].id,
                        user: memberList[i].user,
                    };
                    break; 
                }
            }
            this.monthValidation(firstActiveMember,true)
        });
    };

    changeWeek = (number) => {
        this.setState({
            week: parseInt(this.state.week, 10) + number,
            isloading: true,
        }, () => {
            this.getArrDay(number);
        });
    };

    resetWeek = () => {
        this.setState({
            week: 0
        }, () => {
            this.getArrDay();
        });
    };

    getShift = async() => {

        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        let condition = { company: (checkLogin && checkLogin.company && checkLogin.company.id) ? checkLogin.company.id : null };
        this.dataFilter.condition = JSON.stringify(condition);
        //debugger;
        this.props.shiftActions.getListShift(this.dataFilter, (err, data) => {

            if (err) {
                // Config.popup.show('INFO',err.message);
                this.setState({
                    shiftList: []
                });
            }
            else {
                data = data.filter(item => !(item.name === "CUSTOM"));
                data.push(
                    {
                        color: "#EE766D",
                        company: null,
                        end: 0,
                        id: "OFF",
                        name: "OFF",
                        start: 0,
                        team: null
                    }
                );
                this.setState({
                    shiftList: data
                });
            }
        });
    };

    closeHolidayModal = () => {
        this.setState({ isHoliday:false });
        localStorage.removeItem('ROSTERPAGESTATE')
    }

    closeBookedHoliday = () => {
        this.setState({ isBookedHoliday:false });
        localStorage.removeItem('ROSTERPAGESTATE');        
    }

    closeRejectedHoliday = () => {
        this.setState({ isRejectedHoliday:false });
        localStorage.removeItem('ROSTERPAGESTATE')        
    }

    _confirmModal = () => {

        let notificationID = this.state.bookHoliday.notiID;

        let params = {
            "notificationid": notificationID,
            "holiday_request": true
        };

        this.props.rosterActions.confirmHolidayRequest(params , (err, data) => {
            console.log(data);
            if(data.isProcessed === false){
                this.setState({
                    isHoliday:false,
                    isholidayRequestProcessed:true
                });
            }else{
               
                    this.setState({
                        isHoliday:false,
                    });
              
            setTimeout(()=>{
                this.setState({
                    isBookedHoliday:true
                });
            },1000)
            localStorage.removeItem('ROSTERPAGESTATE')
            this.init();
            this.getShift();
            setTimeout(() => {
                this.setState({
                    isBookedHoliday:false
                });
            }, 2000);
        }
        })
        localStorage.removeItem('teamsId');
    }

    _removeModal = () => {

        let notificationID = this.state.bookHoliday.notiID;

        let params = {
            "notificationid": notificationID,
            "holiday_request": false
        };

        this.props.rosterActions.confirmHolidayRequest(params , (err, data) => {
            if(data.isProcessed === false){
                this.setState({
                    isHoliday:false,
                    isholidayRequestProcessed:true
                });
            }else{
                setTimeout(()=>{
                    this.setState({
                        isRejectedHoliday: true,
                    })
                    },2000)
                setTimeout(()=>{
                this.setState({
                    isHoliday:false,
                })
                },1000)
                
            localStorage.removeItem('ROSTERPAGESTATE')
            this.init();
            this.getShift();
            setTimeout(() => {
                this.setState({
                    isRejectedHoliday:false
                });
            }, 3000);
        }
        })
        localStorage.removeItem('teamsId');
    }

    closeModal = () => {
        this.setState({ isRemoveMember: false });
      };
    closeCreateRosterModal = () => {
        this.setState({ isCreateNewRosterShift:false });
    };
    
    clockOutModal = () => {
        this.setState({ isClockedOut: true,clockActivateStep:0,flagActivateClock:false });
     }
    
    closeClockModal = () => {
       this.setState({ flagActivateClock:false,isClockedOut: false ,clockActivateStep:0});
    }

    toggleCreateRoster = (arrMemberCreate) => {
        if (this.state.dataCreateStaff.length === 0 && this.state.flagTab === 1 ) {
            this.setState({ isCreateNewRosterShift: true });
            return;
        }
        if (this.state.dataCreateStaff.length === 0 && this.state.flagTab === 2 ) {
            this.setState({ isCreateNewRosterShift: true });
            return;
        }
        if (this.state.shiftList.length === 0 && this.state.flagTab === 1) {
            //Config.popup.show('INFO', 'Please make at least one shift before create the roster');
            this.setState({ isRemoveMember: true });
            return;
        }
        if (this.state.shiftList.length === 0 && this.state.flagTab === 2) {
            //Config.popup.show('INFO', 'Please make at least one shift before create the roster');
            this.setState({ isRemoveMember: true });
            return;
        }
        this.setState({
            flagCreate: !this.state.flagCreate,
            arrMemberCreate: arrMemberCreate ? arrMemberCreate : null
        })
    };

    toggleInfo = (infoRoster) => {
        this.setState({
            dataInfo: infoRoster
        })
    };

    toggleConfirmRoster = () => {
        this.setState({
            flagConfirm: !this.state.flagConfirm
        })
    };

    toggleActivateClock = () => {
        this.setState({
            flagActivateClock: !this.state.flagActivateClock
        })
    };

    setDataListTeam = (data, id) => {
        this.setState({
            idTeam: this.state.idTeam ? this.state.idTeam : (id ? id : null)
        }, () => {
            this._getMemberTeamList();
            this.init();
            this.getShift();
        });
    };

    createShift = () => {
        browserHistory.push('/shift');
    };

    createStaffHours = () => {
        browserHistory.push('/staffhours');
    };

    getDataStaffChoose = (data) => {
        this.setState({
            dataCreateStaff: data
        })
    };

    toggleClock = (checked) => {
        this.setState({
            isClockEnabled: false,
            flagActivateClock: true
        });
        this.props.teamActions.toggleClock({ id: this.state.idTeam }, (err, data) => {
            if (err) {
                Config.alertMess(err);
            }
            else {
                console.log('toggleClock', data);                
                // this.setState({
                //     isClockEnabled: data[0].is_clock_enabled,
                //     flagActivateClock: true
                // });
            }
        });
    };

    toggleClockOut = async() => {
        setTimeout(()=>{
            this.closeClockModal();
        },1000); 
      

        await this.props.teamActions.toggleClock({ id: this.state.idTeam }, (err, data) => {
            if (err) {
                Config.alertMess(err);
            }
            else {
                this.setState({
                    isClockEnabled: data[0].is_clock_enabled,
                    flagActivateClock: false,
                    clockActivateStep:0
                });
            }
        });
    };

    handleClockInOut = () => {
        // Handle clock in/out logic based on the current state
        if (this.state.isClockEnabled) {
            this.clockOutModal();
        } else {
            this.toggleClock();
        }
    };

    addRoster = (data, flagNoti, note, cb) => {
      let params = { team: this.state.idTeam, notification: flagNoti ? flagNoti : 'Y', rosters: JSON.stringify(data), note: note };
        this.props.rosterActions.addRoster(params, (err, data) => {

            if (err) {
                // Config.popup.show('INFO',err.message);
                cb && cb(err, data);
            }
            else {
                if (this.refs['WeekView']) this.refs['WeekView'].clearDataChoose();
                if (this.refs['DayView']) this.refs['DayView'].clearDataChoose();
                if (this.refs['MonthDisplay']) this.refs['MonthDisplay'].clearDataChoose();    
                this.setState({
                    dataCreateStaff: [],
                    flagCreate: false,
                    flagConfirm: true,
                });
                this.init();
                cb && cb(null, data);
            }
        });
    };

    changeDay = (number) => {
        this.setState({
            arrMemberCreate: null,
            onlyDay: moment(this.state.onlyDay).add(number, 'day').format()
        }, () => {
            this.init();
        });
    };

    _removeRoster= (id, myTeam) => {
            let params = { id: id };
            if (myTeam && myTeam.id) {
                params.team = myTeam.id;
            }
            this.props.rosterActions.removeRoster(params, (err, data) => {
                if (err) {
                    Config.alertMess(err);
                }
                else {
                    this.setState({
                        dataInfo: null,
                    });
                    this.init();

                }
            });
        
    };

    _getRosterClock = (id, cb) => {
        this.props.rosterActions.getRosterClock(id, (err, data) => {
            if (err) {
                Config.alertMess(err);
                // this.setState({
                //     clockList: [],
                // });
                cb && cb(err, data);
            }
            else {
                this.setState({
                    clockList: data,
                });
                cb && cb(null, data);
            }
        });
    };

    editRoster = (data, cb) => {
        this.props.rosterActions.editRoster(data, (err, data) => {
            if (err) {
                Config.alertMess(err);
                cb && cb(err, data);
            }
            else {
                this.setState({
                    dataInfo: null,
                    flagConfirm: true
                });
                this.init();
                cb && cb(null, data);
            }
        });
    };

    popupInfo = (text) => {
        Config.popup.show('INFO', [text]);
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        a = a[0] + ':' + second;
        return a;

    };

    convertTimeToMinutes(timeString) {
        let [hours, minutes] = timeString.split(':');
        return parseInt(hours) * 60 + parseInt(minutes);
    }

    genCSVData = (arrDay, resultFilter, memberList, user) => {
        let res = [];
        memberList && memberList.map((key, idx) => {
            if (key.status === 'INVITING' || (user && user !== key.user.id)) return null;


            let arr = [];
            if (resultFilter) {
                arr = resultFilter.filter(i => i.staff && i.staff.id === key.user.id);
                if (arr.length > 0) res.push(arr[0]);
            }
        });

        const headerArray = [];
        const headerDetails = [];
        arrDay.map((val, idx) => {
            headerDetails[idx] = {};
            headerDetails[idx].label = moment(val).format('ddd').toUpperCase() + '\r\n' + moment(val).format('M/D/YY');
            if (idx == 0) {
                headerDetails[idx].key = 'mon';
            } if (idx == 1) {
                headerDetails[idx].key = 'tue';
            } if (idx == 2) {
                headerDetails[idx].key = 'wen';
            } if (idx == 3) {
                headerDetails[idx].key = 'the';
            } if (idx == 4) {
                headerDetails[idx].key = 'fri';
            } if (idx == 5) {
                headerDetails[idx].key = 'sat';
            } if (idx == 6) {
                headerDetails[idx].key = 'sun';
            }
            headerArray.push(headerDetails[idx]);
        });

        headerArray.push({ label: "SCHED" + '\r\n' + "HOURS", key: "total" });
        headerArray.push({ label: "ACTUAL" + '\r\n' + "HOURS", key: "clockedhrs" });
        headerArray.unshift({ label: "EMPLOYEE NAME", key: "fullName" });
        const headers = headerArray;
        const data = [];
        const dataDetails = [];


        if (res.length > 0) {
            let i = 0;
            for (const row of res) {
                const { activities, staff } = row;
                dataDetails[i] = {};

                for (let [index, acti] of activities.entries()) {
                    let Day = acti.time ? moment(acti.time).format('ddd') : '';

                    if (Day == 'Mon') {
                        let monHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let monClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }

                        if (acti.shift === null) {
                            monHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            monHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            monHour = (acti.shift.end - acti.shift.start);
                        }

                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            monClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            monClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(monClock);
                        }

                        dataDetails[i].mon = this.changeTimeToSecond(monHour);
                        if (monClock) {
                        dataDetails[i].mon = `${dataDetails[i].mon} (${monClock})`
                        }
                        else{
                            dataDetails[i].mon = dataDetails[i].mon;
                        }
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let MondayHours = dataDetails[i].mon;
                        let MondayHoursValue = MondayHours.split(':')
                        let MondayMinute = parseInt(MondayHoursValue[1]).toString().padStart(2, '0');
                        let MondayHour = parseInt(MondayHoursValue[0]).toString().padStart(2, '0');
                        
                        function divideBy60(MondayMinute) {
                            let quotient = Math.floor(MondayMinute / 60);
                            let remainder = MondayMinute % 60;
                          
                            return { quotient, remainder };
                        }

                        let MondayClockedHours = monClock;
                        if(MondayClockedHours != 0){
                        let MondayClockHoursValue = MondayClockedHours.split(':')                       
                        let MondayClockMinute = parseInt(MondayClockHoursValue[1]).toString().padStart(2, '0');
                        let MondayClockHour = parseInt(MondayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(MondayClockMinute){
                            let quotients = Math.floor(MondayClockMinute / 60);
                            let remain = MondayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(MondayClockMinute);

                        let TotalMondayClockHrs = (parseInt(MondayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalMondayClockMinutes = remain.toString().padStart(2, '0');
                        let MondayClockTotalTime = TotalMondayClockHrs + "." + TotalMondayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalMondayClockHrs, TotalMondayClockMinutes) : MondayClockTotalTime;

                        }

                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                             // Add condition to remove ":00" when the hour exceeds 24
                            // let returntotalMin = returnHour >= 0 ? `${returnHour}:${totalMinutes}` : `${returnHour}:${totalMinutes}:00`;
                            let returntotalMin = (returnHour) + (totalMinutes / 60)

                            console.log(".......... 1 ",returntotalMin.toFixed(2))
                            return returntotalMin.toFixed(2);
                            
                        }
                        let { quotient, remainder } = divideBy60(MondayMinute);
                      
                        let TotalMondayHours = (parseInt(MondayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalMondayMinutes = remainder.toString().padStart(2, '0');
                        let MondayTotalTime = TotalMondayHours + "." + TotalMondayMinutes;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalMondayHours,TotalMondayMinutes): MondayTotalTime;
                        console.log("--------- 1",dataDetails[i].total)
                    }

                    if (Day == 'Tue') {
                        let tueHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let tueClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }
                        if (acti.shift === null) {
                            tueHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            tueHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            tueHour = (acti.shift.end - acti.shift.start);
                        }

                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            tueClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            tueClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(tueClock);
                        }
                        dataDetails[i].tue = this.changeTimeToSecond(tueHour);
                        if (tueClock) {
                        dataDetails[i].tue = `${dataDetails[i].tue} (${tueClock})`
                        }
                        else{
                            dataDetails[i].tue = dataDetails[i].tue;
                        }                        
                        // dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + tueHour : tueHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let TuesdayHours = dataDetails[i].tue;
                        let TuesdayHoursValue = TuesdayHours.split(':')
                        let TuesdayHour = parseInt(TuesdayHoursValue[0]).toString().padStart(2, '0')
                        let TuesdayMinute = parseInt(TuesdayHoursValue[1]).toString().padStart(2, '0')
                       
                        function divideBy60(TuesdayMinute) {
                            let quotient = Math.floor(TuesdayMinute / 60);
                            let remainder = TuesdayMinute % 60;
                            return { quotient, remainder };
                          
                        }

                        let TuesdayClockedHours = tueClock;
                        if(TuesdayClockedHours != 0){
                        let TuesdayClockHoursValue = TuesdayClockedHours.split(':')                       
                        let TuesdayClockMinute = parseInt(TuesdayClockHoursValue[1]).toString().padStart(2, '0');
                        let TuesdayClockHour = parseInt(TuesdayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(TuesdayClockMinute){
                            let quotients = Math.floor(TuesdayClockMinute / 60);
                            let remain = TuesdayClockMinute % 60;
                            return { quotients, remain };
                        }
                        let { quotients, remain } = divide(TuesdayClockMinute);
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 1 ? returntotalMin + ":00" : returntotalMin;
                        }

                        let TotalTuesdayClockHrs = (parseInt(TuesdayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalTuesdayClockMinutes = remain.toString().padStart(2, '0');
                        let TuesdayClockTotalTime = TotalTuesdayClockHrs + ":" + TotalTuesdayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalTuesdayClockHrs, TotalTuesdayClockMinutes) : TuesdayClockTotalTime;

                        }

                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            // let returntotalMin=returnHour+ ":" +totalMinutes;
                            let returntotalMin = returnHour >= 0 ? `${returnHour}:${totalMinutes}` : `${returnHour}:${totalMinutes}:00`;
                            return returntotalMin;
                            
                        }

                        let { quotient, remainder } = divideBy60(TuesdayMinute);
                      
                        let TotalTuesdayHours = (parseInt(TuesdayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalTuesdayMinute = remainder.toString().padStart(2, '0');
                        let TuesdayTotalTime = TotalTuesdayHours + ":" + TotalTuesdayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalTuesdayHours,TotalTuesdayMinute) : TuesdayTotalTime;
                    }

                    if (Day == 'Wed') {
                        let wenHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let WedClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }

                        if (acti.shift === null) {
                            wenHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            wenHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            wenHour = (acti.shift.end - acti.shift.start);
                        }
                        dataDetails[i].wen = this.changeTimeToSecond(wenHour);

                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            WedClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            WedClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(WedClock);
                        }
                        if (WedClock) {
                            dataDetails[i].wen = `${dataDetails[i].wen} (${WedClock})`
                            }
                            else{
                                dataDetails[i].wen = dataDetails[i].wen;
                        } 
                        // dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + wenHour : wenHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let WednesdayHours = dataDetails[i].wen;
                        let WednesdayHoursValue = WednesdayHours.split(':')
                        let WednesdayHour = parseInt(WednesdayHoursValue[0]).toString().padStart(2, '0')
                        let WednesdayMinute = parseInt(WednesdayHoursValue[1]).toString().padStart(2, '0')
                       

                        function divideBy60(WednesdayMinute) {
                            let quotient = Math.floor(WednesdayMinute / 60);
                            let remainder = WednesdayMinute % 60;
                            return { quotient, remainder };
                          
                        }

                        let WednesdayClockedHours = WedClock;
                        if(WednesdayClockedHours != 0){
                        let WednesdayClockHoursValue = WednesdayClockedHours.split(':')                       
                        let WednesdayClockMinute = parseInt(WednesdayClockHoursValue[1]).toString().padStart(2, '0');
                        let WednesdayClockHour = parseInt(WednesdayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(WednesdayClockMinute){
                            let quotients = Math.floor(WednesdayClockMinute / 60);
                            let remain = WednesdayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                         
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(WednesdayClockMinute);

                        let TotalWednesdayClockHrs = (parseInt(WednesdayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalWednesdayClockMinutes = remain.toString().padStart(2, '0');
                        let WednesdayClockTotalTime = TotalWednesdayClockHrs + ":" + TotalWednesdayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalWednesdayClockHrs, TotalWednesdayClockMinutes) : WednesdayClockTotalTime;

                        }

                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            // let returntotalMin=returnHour+ ":" +totalMinutes;
                            let returntotalMin = returnHour >= 0 ? `${returnHour}:${totalMinutes}` : `${returnHour}:${totalMinutes}:00`;
                            return returntotalMin;
                            
                        }

                        let { quotient, remainder } = divideBy60(WednesdayMinute);
                      
                        let TotalWednesdayHours = (parseInt(WednesdayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalWednesdayMinute = remainder.toString().padStart(2, '0');
                        let WednesdayTotalTime = TotalWednesdayHours + ":" + TotalWednesdayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalWednesdayHours,TotalWednesdayMinute) : WednesdayTotalTime;
                    }

                    if (Day == 'Thu') {
                        let theHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let ThuClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }
                        if (acti.shift === null) {
                            theHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            theHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            theHour = (acti.shift.end - acti.shift.start);
                        }
                        dataDetails[i].the = this.changeTimeToSecond(theHour);
                        
                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            ThuClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            ThuClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(ThuClock);
                        }
                        if (ThuClock) {
                            dataDetails[i].the = `${dataDetails[i].the} (${ThuClock})`
                            }
                            else{
                                dataDetails[i].the = dataDetails[i].the;
                        }
                        //dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + theHour : theHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let ThursdayHours = dataDetails[i].the;
                        let ThursdayHoursValue = ThursdayHours.split(':')
                        let ThursdayHour = parseInt(ThursdayHoursValue[0]).toString().padStart(2, '0')
                        let ThursdayMinute = parseInt(ThursdayHoursValue[1]).toString().padStart(2, '0')

                        function divideBy60(ThusdayMinute) {
                            let quotient = Math.floor(ThusdayMinute / 60);
                            let remainder = ThusdayMinute % 60;
                            return { quotient, remainder };
                        }

                        let ThusdayClockedHours = ThuClock;
                        if(ThusdayClockedHours != 0){
                        let ThusdayClockHoursValue = ThusdayClockedHours.split(':')                       
                        let ThusdayClockMinute = parseInt(ThusdayClockHoursValue[1]).toString().padStart(2, '0');
                        let ThusdayClockHour = parseInt(ThusdayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(ThusdayClockMinute){
                            let quotients = Math.floor(ThusdayClockMinute / 60);
                            let remain = ThusdayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(ThusdayClockMinute);

                        let TotalThusdayClockHrs = (parseInt(ThusdayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalThusdayClockMinutes = remain.toString().padStart(2, '0');
                        let ThusdayClockTotalTime = TotalThusdayClockHrs + ":" + TotalThusdayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalThusdayClockHrs, TotalThusdayClockMinutes) : ThusdayClockTotalTime;

                        }

                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            // let returntotalMin=returnHour+ ":" +totalMinutes;
                            let returntotalMin = returnHour >= 0 ? `${returnHour}:${totalMinutes}` : `${returnHour}:${totalMinutes}:00`;
                            return returntotalMin;
                            
                        }
                        let { quotient, remainder } = divideBy60(ThursdayMinute);
                        let TotalThursdayHours =(parseInt(ThursdayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalThursdayMinute = remainder.toString().padStart(2, '0');
                        let ThursdayTotalTime = TotalThursdayHours + ":" + TotalThursdayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalThursdayHours,TotalThursdayMinute) : ThursdayTotalTime;
                    }
                    if (Day == 'Fri') {
                        let friHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let friClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }

                        if (acti.shift === null) {
                            friHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            friHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            friHour = (acti.shift.end - acti.shift.start);
                        }
                        dataDetails[i].fri = this.changeTimeToSecond(friHour);
                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            friClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            friClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(friClock);
                        }
                        if (friClock) {
                            dataDetails[i].fri = `${dataDetails[i].fri} (${friClock})`
                            }
                            else{
                                dataDetails[i].fri = dataDetails[i].fri;
                        }
                        // dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + friHour : friHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let FridayHours = dataDetails[i].fri;
                        let FridayHoursValue = FridayHours.split(':')
                        let FridayHour = parseInt(FridayHoursValue[0]).toString().padStart(2, '0')
                        let FridayMinute = parseInt(FridayHoursValue[1]).toString().padStart(2, '0')


                        function divideBy60(FridayMinute) {
                            let quotient = Math.floor(FridayMinute / 60);
                            let remainder = FridayMinute % 60;
                            return { quotient, remainder };
                        }

                        let FridayClockedHours = friClock;
                        if(FridayClockedHours != 0){
                        let FridayClockHoursValue = FridayClockedHours.split(':')                       
                        let FridayClockMinute = parseInt(FridayClockHoursValue[1]).toString().padStart(2, '0');
                        let FridayClockHour = parseInt(FridayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(FridayClockMinute){
                            let quotients = Math.floor(FridayClockMinute / 60);
                            let remain = FridayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(FridayClockMinute);

                        let TotalFridayClockHrs = (parseInt(FridayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalFridayClockMinutes = remain.toString().padStart(2, '0');
                        let FridayClockTotalTime = TotalFridayClockHrs + ":" + TotalFridayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalFridayClockHrs, TotalFridayClockMinutes) : FridayClockTotalTime;

                        }

                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            // let returntotalMin=returnHour+ ":" +totalMinutes;
                            let returntotalMin = returnHour >= 0 ? `${returnHour}:${totalMinutes}` : `${returnHour}:${totalMinutes}:00`;
                            return returntotalMin;
                            
                        }
                        let { quotient, remainder } = divideBy60(FridayMinute);
                        let TotalFridayHours = (parseInt(FridayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalFridayMinute = remainder.toString().padStart(2, '0');
                        let FridayTotalTime = TotalFridayHours + ":" + TotalFridayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalFridayHours,TotalFridayMinute) : FridayTotalTime;
                    }
                    if (Day == 'Sat') {
                        let satHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let satClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }
                        if (acti.shift === null) {
                            satHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            satHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            satHour = (acti.shift.end - acti.shift.start);

                        }
                        dataDetails[i].sat = this.changeTimeToSecond(satHour);
                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            satClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            satClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(satClock);
                        }
                        if (satClock) {
                            dataDetails[i].sat = `${dataDetails[i].sat} (${satClock})`
                            }
                            else{
                                dataDetails[i].sat = dataDetails[i].sat;
                        }
                        //dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + satHour : satHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let SaturdayHours = dataDetails[i].sat;
                        let SaturdayHoursValue = SaturdayHours.split(':')
                        let SaturdayHour = parseInt(SaturdayHoursValue[0]).toString().padStart(2, '0')
                        let SaturdayMinute = parseInt(SaturdayHoursValue[1]).toString().padStart(2, '0')
                        

                        function divideBy60(SaturdayMinute) {
                            let quotient = Math.floor(SaturdayMinute / 60);
                            let remainder = SaturdayMinute % 60;
                            return { quotient, remainder };
                      
                        }
                        let SaturdayClockedHours = satClock;
                        if(SaturdayClockedHours != 0){
                        let SaturdayClockHoursValue = SaturdayClockedHours.split(':')                       
                        let SaturdayClockMinute = parseInt(SaturdayClockHoursValue[1]).toString().padStart(2, '0');
                        let SaturdayClockHour = parseInt(SaturdayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(SaturdayClockMinute){
                            let quotients = Math.floor(SaturdayClockMinute / 60);
                            let remain = SaturdayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + "." + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(SaturdayClockMinute);

                        let TotalSaturdayClockHrs = (parseInt(SaturdayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalSaturdayClockMinutes = remain.toString().padStart(2, '0');
                        let SaturdayClockTotalTime = TotalSaturdayClockHrs + ":" + TotalSaturdayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalSaturdayClockHrs, TotalSaturdayClockMinutes) : SaturdayClockTotalTime;

                        }
                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            // let returntotalMin=returnHour+ ":" +totalMinutes;
                            let returntotalMin = returnHour >= 0 ? `${returnHour}.${totalMinutes}` : `${returnHour}:${totalMinutes}:00`;
                            console.log("returnHour sat",returnHour,returntotalMin);
                            return returntotalMin;
                            
                        }
                        let { quotient, remainder } = divideBy60(SaturdayMinute);
                        let TotalSaturdayHours = (parseInt(SaturdayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalSaturdayMinute = remainder.toString().padStart(2, '0');
                        let SaturdayTotalTime = TotalSaturdayHours + ":" + TotalSaturdayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalSaturdayHours,TotalSaturdayMinute) : SaturdayTotalTime;
                    }
                    if (Day == 'Sun') {
                        let sunHour;
                        let clockInMinutes;
                        let clockOutMinutes;
                        let sunClock;
                        if(acti.clock_in && acti.clock_out){
                            clockInMinutes = this.convertTimeToMinutes(acti.clock_in);
                            clockOutMinutes = this.convertTimeToMinutes(acti.clock_out);
                        }
                        if (acti.shift === null) {
                            sunHour = 0;
                        }
                        else if (acti.shift.end < acti.shift.start) {
                            sunHour = (acti.shift.end + 24 - acti.shift.start);
                        } else {
                            sunHour = (acti.shift.end - acti.shift.start);
                        }
                        dataDetails[i].sun = this.changeTimeToSecond(sunHour);
                        if((acti.clock_in === "") || (acti.clock_out === "")){
                            sunClock = 0;
                        }
                        else{
                            let timeDifferenceMinutes = clockOutMinutes - clockInMinutes;
                            let hours = Math.floor(timeDifferenceMinutes / 60);
                            let minutes = timeDifferenceMinutes % 60;
                            sunClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

                            console.log(sunClock);
                        }
                        if (sunClock) {
                            dataDetails[i].sun = `${dataDetails[i].sun} (${sunClock})`
                            }
                            else{
                                dataDetails[i].sun = dataDetails[i].sun;
                        }
                        // dataDetails[i].total = (dataDetails[i].total) ? dataDetails[i].total + sunHour : sunHour;
                        dataDetails[i].total = (dataDetails[i].total);
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs);
                        let SundayHours = dataDetails[i].sun;
                        let SundayHoursValue = SundayHours.split(':')
                        let SundayHour = parseInt(SundayHoursValue[0]).toString().padStart(2, '0')
                        let SundayMinute = parseInt(SundayHoursValue[1]).toString().padStart(2, '0')


                        function divideBy60(SundayMinute) {
                            let quotient = Math.floor(SundayMinute / 60);
                            let remainder = SundayMinute % 60;
                            return { quotient, remainder };
                        }

                        let SundayClockedHours = sunClock;
                        if(SundayClockedHours != 0){
                        let SundayClockHoursValue = SundayClockedHours.split(':')                       
                        let SundayClockMinute = parseInt(SundayClockHoursValue[1]).toString().padStart(2, '0');
                        let SundayClockHour = parseInt(SundayClockHoursValue[0]).toString().padStart(2, '0');

                        function divide(SundayClockMinute){
                            let quotients = Math.floor(SundayClockMinute / 60);
                            let remain = SundayClockMinute % 60;
                            return { quotients, remain };
                        }
                    
                        function getClockTotalTime(clockedhrs, hour, min) {
                            let hourWithMin = clockedhrs.split(':');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotients, remain } = divide(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotients)).toString().padStart(2, '0');
                            let totalMinutes = remain.toString().padStart(2, '0');
                            let returnHour = parseInt(hour) + parseInt(totalHours);
                            let returntotalMin = returnHour + ":" + totalMinutes;
                            return returnHour < 24 ? returntotalMin + ":00" : returntotalMin;
                        }
                    
                        let { quotients, remain } = divide(SundayClockMinute);

                        let TotalSundayClockHrs = (parseInt(SundayClockHour) + parseInt(quotients)).toString().padStart(2, '0');
                        let TotalSundayClockMinutes = remain.toString().padStart(2, '0');
                        let SundayClockTotalTime = TotalSundayClockHrs + ":" + TotalSundayClockMinutes;
                        dataDetails[i].clockedhrs = (dataDetails[i].clockedhrs) ? getClockTotalTime(dataDetails[i].clockedhrs, TotalSundayClockHrs, TotalSundayClockMinutes) : SundayClockTotalTime;

                        }
                        function getTotalTime(total,hour,min){
                            let hourWithMin = total.split('.');
                            let calminutes = parseInt(hourWithMin[1]).toString().padStart(2, '0');
                            let calhour = parseInt(hourWithMin[0]).toString().padStart(2, '0');
                            let { quotient, remainder } = divideBy60(parseInt(min) + parseInt(calminutes));
                            let totalHours = (parseInt(calhour) + parseInt(quotient)).toString().padStart(2, '0');
                            let totalMinutes = remainder.toString().padStart(2, '0');
                            let returnHour= parseInt(hour)+parseInt(totalHours);
                            let returntotalMin=returnHour+ "." +totalMinutes;
                            // let returntotalMin = returnHour >= 0 ? `${returnHour}.${totalMinutes}` : `${returnHour}:${totalMinutes}:00`;
                            return returntotalMin;
                            
                        }
                        let { quotient, remainder } = divideBy60(SundayMinute);
                        let TotalSundayHours = (parseInt(SundayHour) + parseInt(quotient)).toString().padStart(2, '0');
                        let TotalSundayMinute = remainder.toString().padStart(2, '0');
                        let SundayTotalTime = TotalSundayHours + ":" + TotalSundayMinute;
                        dataDetails[i].total = (dataDetails[i].total) ? getTotalTime(dataDetails[i].total,TotalSundayHours,TotalSundayMinute) : SundayTotalTime;
                    }
                }
                if ((Object.keys(res).length) == i) {
                    break;
                } else {
                    dataDetails[i].fullName = staff.fullName;
                    data.push(dataDetails[i]);
                    i++;
                }

            }
        }
        return {
            headers,
            data
        };

        onLogout = () => {
            this.refs.OverlayTriggerExport1.hide();
            this.refs.OverlayTriggerExport2.hide();
        };
    }

    formatDates = (dateArray) => {
        if (!dateArray || !dateArray.length) {
            return ''; 
        }

        const formattedDates = dateArray.map(this.formatDate.bind(this));
        return formattedDates.join(', ');
    }

    formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const suffix = this.getDaySuffix(day);

        return `${day}${suffix}`;
    }

    getDaySuffix = (day) => {
        if (day >= 11 && day <= 13) {
            return 'th';
        }

        switch (day % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }
    capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    confirmHolidayDetails = () => {
        const { isReqHolidayData } = this.state;
        console.log("isReq",isReqHolidayData);
        
        let holiday ='granted';

        let rosterIDS = [];
        let idTeam;

        if(isReqHolidayData){
        rosterIDS = isReqHolidayData.data.map(({ id }) => id);
        idTeam = isReqHolidayData.data[0].team;
        }       

        this.props.rosterActions.confirmHoliday({rosters: rosterIDS,bookHoliday: holiday,teamid: idTeam}, (err, data) => {
            if (err) {
                console.error("Error fetching confirmHolidays:", err);
                this.setState({
                    isloading: false
                });
            } else {
                
                this.setState({
                    isHoliday:false,
                    isloading: false
                });
                console.log("confirmHoliday",data);
                localStorage.removeItem('ROSTERPAGESTATE');
            }
        });
    }

    rejectHolidayDetails = () => {
        const { isReqHolidayData } = this.state;
        
        let holiday ='rejected';

        let rosterIDS = [];
        let idTeam;

        if(isReqHolidayData){
        rosterIDS = isReqHolidayData.data.map(({ id }) => id);
        idTeam = isReqHolidayData.data[0].team;
        } 
        
        this.props.rosterActions.confirmHoliday({rosters: rosterIDS,bookHoliday: holiday,teamid: idTeam}, (err, data) => {
            if (err) {
                console.error("Error fetching confirmHolidays:", err);
                this.setState({
                    isHoliday:false,
                    isloading: false
                });
            } else {
                // Assuming the API call was successful, update the state with the modified data
                this.setState({                   
                    isHoliday:false,
                    isloading: false
                });
                console.log("rejectHoliday",data);
                localStorage.removeItem('ROSTERPAGESTATE');
            }
        });
    }
    // monthValidated = ()=>{
    //     let {memberList} = this.props
    //     let datasmonth=this.state.rosterList
    //     let arrss=[]
    //     if(memberList && datasmonth){
    //         let tot=memberList.filter(team => team.id === datasmonth.value);
    //         if(tot.length >0){
    //             arrss = arrss.concat(tot)
    //         }
    //     }
    //     this.setState({
    //         arrss:arrss
         
    //     })
    //     console.log('arss value :',arrss);

    // }
    // staff view select options 
    monthValidation = (selectedOptions,isFirst) => {
        let { memberList } = this.props;
        let Data = this.state.rosterList
        let dataChoose = [];
        if (memberList && selectedOptions) {
             let dataTeam = memberList.filter(team => team.id === selectedOptions.value);
                if (dataTeam.length > 0) {
                    dataChoose = dataChoose.concat(dataTeam);
                }
        }
        this.setState({
            selectedTeamMemberData:selectedOptions
        });
       
       console.log('dataChoose filter:',dataChoose);
        this.setState({
            dataChoose: dataChoose
        });

        if(Data){
            let aaaa = Data.filter(item=> item.staff && (item.staff.id === dataChoose[0].user.id));
            if(aaaa.length >0){
                dataChoose = dataChoose.concat(aaaa)
            }  
        }
        this.setState({
            dataChoose : dataChoose
        })
    
        
      console.log('find :',dataChoose);
      console.log('Data :', this.state.rosterList);
        
    };

    toggleUserList = () => {
        this.setState({
            toggleUserList: !this.state.toggleUserList,
           
        }) 
        if(!this.state.toggleUserList){
            this.setState({
                dropdownclose:false, 
            })
        }
      };

      dropdownclose = () => {
        if(this.state.toggleUserList){
            this.setState({
                dropdownclose:true,
                toggleUserList:false
            })
        }
      };
      
      searchHandleChange = (value) => {
        this.setState({ searchValue: value });
      }

    
      _onLogout = () => {
        this.props.mainActions.checkOut((err, data) => {
            window.location.reload();
        });
    };
    changePopup = (data) => {
        this.closeClockModal();
        this.setState({
            clockActivateStep: 1
        })
        this.setState({ isClockEnabled: true,flagActivateClock:true });
    };
    activateClock = (data) => {
        this.setState({ isClockEnabled: true });
    }

    toggleTeamSelectionClick = () => {
        console.log("toggleTeamSelection",this.state.toggleTeamSelection,this.state.isTeamOpened);
        if(this.state.isTeamOpened){
            this.setState({
                toggleTeamSelection: false,
                isTeamOpened: false
            });
        }
        else{
            this.setState({
                toggleTeamSelection: true
            });
    }
}
    isTeamOpened = (value) => {
        console.log("isTeamOpened", value);
        this.setState({
            isTeamOpened: value
        })
    }

    render() {
        let { teamList, memberList} = this.props;
        let dataTab = ['DAY', 'WEEK', 'MONTH'];
        let myTeam = [];
        let monthdata =[];
        let currentUser = {};
      
        if(this.state.dataChoose && this.state.dataChoose.length > 0){
            currentUser = {
                name : this.state.dataChoose[0]?.name,
                id : this.state.dataChoose[0]?.id,
                userid : this.state.dataChoose[0]?.user.id,
            }
        }
 
      
        if(memberList){
            memberList.filter(i=>{
                if(i.status === 'ACTIVE')
                    {monthdata.push({value : i.id ,label : i.name, owner:memberList[0].name,user:i.user})}
                return true
            })

        }
        console.log("...memberList",memberList);
        console.log("...monthdata",monthdata);
   
        if (teamList && this.state.idTeam) {
            myTeam = teamList.find(i => { return (i.id === this.state.idTeam) });
        }
        console.log("...myTeam",myTeam);
        console.log("...teamList",teamList);

        let resultFilter = [];
        if (this.state.rosterList && memberList) {
            let arrTeam = this.state.rosterList.slice(0);
            arrTeam.forEach(function (hash) {
                return function (a) {
                    if (a.staff && a.staff.id && !hash[a.staff.id]) {
                        hash[a.staff.id] = { staff: a.staff, activities: [] };
                        resultFilter.push(hash[a.staff.id]);
                    }
                    if (a.staff && a.staff.id) hash[a.staff.id].activities.push(a);
                };
            }(Object.create(null)));
            for (let i in memberList) {
                let arr = resultFilter.filter(j => j.staff && j.staff.id && j.staff.id === memberList[i].user.id);
                if (arr.length === 0) {
                    resultFilter.push({ staff: memberList[i].user, activities: [] });
                }
            }
        }

        let checkLogin = localStorage.getItem('LOGIN');
        checkLogin = checkLogin ? JSON.parse(checkLogin) : null;

        let showCreareShift = null;
        if (checkLogin.company && checkLogin.company.id && memberList && memberList.length > 0) {
            showCreareShift = memberList.filter(i => i.role === "OWNER" || i.role === "AdMIN" && checkLogin.user.id === i.user.id);
            memberList.filter((i => console.log("=======>", i)))
            showCreareShift = showCreareShift.length > 0;
        }

        let isAdmin = null;
        let isOwner = null;
        if (memberList) {
            isAdmin = memberList.filter(i => ((i.role === 'ADMIN') && i.user && checkLogin.user && i.user.id === checkLogin.user.id));
            isOwner = memberList.filter(i => ((i.role === 'OWNER') && i.user && checkLogin.user && i.user.id === checkLogin.user.id));
        }

        let MemberId = this.getId();

        const csvData = this.genCSVData(this.state.arrDay, resultFilter, memberList, MemberId);


        let login = localStorage.getItem('LOGIN');
        login = login ? JSON.parse(login) : null;
        let toggleTeamSelection = this.state.toggleTeamSelection;
        return (
            <div className="full_w full_h height-100vh" style={{ position: 'relative' }}  onClick={this.toggleTeamSelectionClick}>
              
                <Header isSearch={true}
                                teamList={teamList}
                                myTeam={myTeam}
                                toggleTeamSelection={toggleTeamSelection}
                                dataListTeam={this.setDataListTeam}
                                changeDropDownTeam={this.changeDropDownTeam}
                                type={1}
                                login={login}
                                onLogout={this._onLogout}
                                isTeamOpened={this.isTeamOpened}
                                />
                                {/* {console.log("team list",this.props.teamList)}
                {console.log(" myTeam",this.props.myTeam)}
                {console.log(" myTeam",this.props.dataListTeam)}
                {console.log(" myTeam",this.props.changeDropDownTeam)} */}
                {!!this.state.isLoadingExport &&
                    <div className="">
                         <div><Loaderview/></div>
                        {/* <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30} /> */}
                    </div>
                }
                
                {this.state.isloading &&

                <Loaderview/>
    }


                <div className="register body_container calendar-view row1 my-team-detail my-team roster-view roster-container" onClick={this.dropdownclose}>
                    <div className="col-sm-12 display_col padding-0 full_h_1" style={{ position: 'relative',width:'97%' }}>
                   
                        <div className=        {`search-bar col-sm-12 shadow-11 display_row align-center sub-header-fixed ${this.state.headerRosterScroll ? 'header-roster-scroll' : ''}`} style={{ top: this.state.fixedValue }}>
                            {/* <SearchBar isSearch={true}
                                teamList={teamList}
                                myTeam={myTeam}
                                dataListTeam={this.setDataListTeam}
                                changeDropDownTeam={this.changeDropDownTeam} /> */}
                                     {this.state.flagTab !==2 && 
                            <div className="search-view" style={{ flexWrap: 'wrap' }}>
                                {/*TODO: permission to view clock in/out*/}
                                <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">View Staff Hours</Tooltip>} >
                                    <div className="display_row align-center btn-new-event button-resp ">
                                        {/* <div className="toggle-switch small-switch">
                                            <input type="checkbox" className="toggle-switch-checkbox"
                                                name="toggleSwitch" id="toggleSwitches"
                                                checked={this.state.isClockEnabled}
                                                onChange={e => this.toggleClock(e.target.checked)}
                                            />
                                            <label className="toggle-switch-label" htmlFor="toggleSwitches">
                                                <span className="toggle-switch-inner"></span>
                                                <span className="toggle-switch-switch"></span>
                                            </label>
                                        </div> */}
                                    </div> 
                                </OverlayTrigger>
                              
                              {showCreareShift &&
                                    <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">Create Shift</Tooltip>} >
                                        <div className="display_row1 align-center btn-new-event button-resp"
                                            style={{ opacity: showCreareShift ? 1 : 0.2 }}
                                            onClick={this.createShift}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <g clip-path="url(#clip0_4098_96)">
    <path d="M20.8721 10C20.8721 9.87852 20.8699 9.7575 20.8656 9.63707C20.6746 4.28234 16.2734 0 10.8721 0C5.4707 0 1.06957 4.28234 0.878555 9.63707C0.874258 9.7575 0.87207 9.87852 0.87207 10C0.87207 15.5229 6.00887 19.6371 10.8721 19.6371C15.8234 19.6371 20.8721 15.5229 20.8721 10Z" fill="#FF5876"/>
    <path d="M10.8721 19.2741C5.47078 19.2741 1.07043 14.9916 0.879336 9.63696C0.875 9.75751 0.87207 9.87833 0.87207 9.99989C0.87207 15.5227 5.34922 19.9999 10.8721 19.9999C16.3949 19.9999 20.8721 15.5227 20.8721 9.99989C20.8721 9.87833 20.8691 9.75751 20.8648 9.637C20.6737 14.9917 16.2734 19.2741 10.8721 19.2741Z" fill="#E6485D"/>
    <path d="M19.2088 9.63704C19.0188 5.19692 15.359 1.65552 10.8721 1.65552C6.38516 1.65552 2.72539 5.19692 2.53539 9.63704C2.53024 9.75739 2.53536 17.8136 10.8721 17.8136C19.2088 17.8136 19.2139 9.75739 19.2088 9.63704Z" fill="#E4EEF9"/>
    <path d="M10.872 17.6185C6.38517 17.6185 2.72599 14.077 2.53591 9.63696C2.53075 9.75735 2.52759 9.87821 2.52759 9.99985C2.52759 14.6084 6.26353 18.3443 10.872 18.3443C15.4806 18.3443 19.2165 14.6084 19.2165 9.99989C19.2165 9.87825 19.2133 9.75735 19.2082 9.637C19.0181 14.0771 15.3589 17.6185 10.872 17.6185Z" fill="#D5E0F2"/>
    <path d="M13.9031 7.07218C13.6652 6.83761 13.2822 6.84022 13.0477 7.07804L11.6284 8.51698L11.771 9.20202L12.434 9.42288L13.909 7.92753C14.1435 7.68972 14.1409 7.30675 13.9031 7.07218Z" fill="#405B6C"/>
    <path d="M10.0158 8.57203L6.79702 5.70359C6.54761 5.48132 6.1653 5.50336 5.94308 5.75277C5.72085 6.00214 5.74284 6.38449 5.99226 6.60671L9.27448 9.53164L9.95843 9.30351L10.0158 8.57203Z" fill="#405B6C"/>
    <path d="M15.1547 13.8999C15.0861 13.8999 15.0171 13.8767 14.9604 13.829L11.1294 10.6112C11.0015 10.5037 10.9849 10.313 11.0923 10.1851C11.1997 10.0572 11.3905 10.0406 11.5184 10.148L15.3494 13.366C15.4772 13.4734 15.4939 13.6642 15.3865 13.792C15.3267 13.8632 15.241 13.8999 15.1547 13.8999Z" fill="#86DCFF"/>
    <path d="M9.27454 9.53187L9.83938 10.0352L10.5648 9.78875L10.5254 9.02629L10.0159 8.57227C9.66145 8.78527 9.39313 9.12656 9.27454 9.53187Z" fill="#2D4456"/>
    <path d="M11.6284 8.51709L11.1482 9.00397V9.95967H11.9047L12.4341 9.42299C12.2886 9.02939 11.9985 8.70623 11.6284 8.51709Z" fill="#2D4456"/>
    <path d="M11.8401 9.63697C11.6932 9.24518 11.3152 8.96631 10.8721 8.96631C10.4289 8.96631 10.0509 9.24518 9.90398 9.63697C9.86163 9.7499 9.90117 10.6706 10.8721 10.6706C11.843 10.6706 11.8825 9.7499 11.8401 9.63697Z" fill="#FF5876"/>
    <path d="M10.872 10.3079C10.4289 10.3079 10.051 10.029 9.90408 9.63721C9.8617 9.75014 9.83838 9.87236 9.83838 10.0001C9.83838 10.571 10.3012 11.0337 10.872 11.0337C11.4428 11.0337 11.9056 10.5709 11.9056 10.0001C11.9056 9.87236 11.8822 9.75014 11.8399 9.63721C11.693 10.029 11.3151 10.3079 10.872 10.3079Z" fill="#E6485D"/>
    <path d="M11.175 3.65514V2.71658C11.175 2.54924 11.0393 2.41357 10.872 2.41357C10.7046 2.41357 10.569 2.54924 10.569 2.71658V3.65514C10.569 3.82248 10.7046 3.95814 10.872 3.95814C11.0394 3.95814 11.175 3.82248 11.175 3.65514Z" fill="#405B6C"/>
    <path d="M10.569 16.3448V17.2833C10.569 17.4507 10.7046 17.5863 10.872 17.5863C11.0394 17.5863 11.175 17.4507 11.175 17.2833V16.3448C11.175 16.1774 11.0393 16.0417 10.872 16.0417C10.7046 16.0417 10.569 16.1774 10.569 16.3448Z" fill="#405B6C"/>
    <path d="M18.1554 10.303C18.3228 10.303 18.4584 10.1674 18.4584 10C18.4584 9.83269 18.3227 9.69702 18.1554 9.69702H17.2168C17.0495 9.69702 16.9138 9.83269 16.9138 10C16.9138 10.1674 17.0495 10.303 17.2168 10.303H18.1554Z" fill="#405B6C"/>
    <path d="M3.58865 9.69702C3.42127 9.69702 3.28564 9.83269 3.28564 10C3.28564 10.1674 3.42127 10.303 3.58865 10.303H4.52721C4.69459 10.303 4.83021 10.1674 4.83021 10C4.83021 9.83269 4.69455 9.69702 4.52721 9.69702H3.58865Z" fill="#405B6C"/>
  </g>
  <defs>
    <clipPath id="clip0_4098_96">
      <rect width="20" height="20" fill="white" transform="translate(0.87207)"/>
    </clipPath>
  </defs>
</svg>
                                        </div>
                                    </OverlayTrigger>
                                }
                                {showCreareShift &&
                                    <div className="display_row1 align-center btn-new-event button-not-resp toolset d-flex gap-2"
                                        style={{ opacity: showCreareShift ? 1 : 0.2, marginLeft: 0 }}
                                        onClick={this.createShift}>
                                       <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <g clip-path="url(#clip0_4098_96)">
    <path d="M20.8721 10C20.8721 9.87852 20.8699 9.7575 20.8656 9.63707C20.6746 4.28234 16.2734 0 10.8721 0C5.4707 0 1.06957 4.28234 0.878555 9.63707C0.874258 9.7575 0.87207 9.87852 0.87207 10C0.87207 15.5229 6.00887 19.6371 10.8721 19.6371C15.8234 19.6371 20.8721 15.5229 20.8721 10Z" fill="#FF5876"/>
    <path d="M10.8721 19.2741C5.47078 19.2741 1.07043 14.9916 0.879336 9.63696C0.875 9.75751 0.87207 9.87833 0.87207 9.99989C0.87207 15.5227 5.34922 19.9999 10.8721 19.9999C16.3949 19.9999 20.8721 15.5227 20.8721 9.99989C20.8721 9.87833 20.8691 9.75751 20.8648 9.637C20.6737 14.9917 16.2734 19.2741 10.8721 19.2741Z" fill="#E6485D"/>
    <path d="M19.2088 9.63704C19.0188 5.19692 15.359 1.65552 10.8721 1.65552C6.38516 1.65552 2.72539 5.19692 2.53539 9.63704C2.53024 9.75739 2.53536 17.8136 10.8721 17.8136C19.2088 17.8136 19.2139 9.75739 19.2088 9.63704Z" fill="#E4EEF9"/>
    <path d="M10.872 17.6185C6.38517 17.6185 2.72599 14.077 2.53591 9.63696C2.53075 9.75735 2.52759 9.87821 2.52759 9.99985C2.52759 14.6084 6.26353 18.3443 10.872 18.3443C15.4806 18.3443 19.2165 14.6084 19.2165 9.99989C19.2165 9.87825 19.2133 9.75735 19.2082 9.637C19.0181 14.0771 15.3589 17.6185 10.872 17.6185Z" fill="#D5E0F2"/>
    <path d="M13.9031 7.07218C13.6652 6.83761 13.2822 6.84022 13.0477 7.07804L11.6284 8.51698L11.771 9.20202L12.434 9.42288L13.909 7.92753C14.1435 7.68972 14.1409 7.30675 13.9031 7.07218Z" fill="#405B6C"/>
    <path d="M10.0158 8.57203L6.79702 5.70359C6.54761 5.48132 6.1653 5.50336 5.94308 5.75277C5.72085 6.00214 5.74284 6.38449 5.99226 6.60671L9.27448 9.53164L9.95843 9.30351L10.0158 8.57203Z" fill="#405B6C"/>
    <path d="M15.1547 13.8999C15.0861 13.8999 15.0171 13.8767 14.9604 13.829L11.1294 10.6112C11.0015 10.5037 10.9849 10.313 11.0923 10.1851C11.1997 10.0572 11.3905 10.0406 11.5184 10.148L15.3494 13.366C15.4772 13.4734 15.4939 13.6642 15.3865 13.792C15.3267 13.8632 15.241 13.8999 15.1547 13.8999Z" fill="#86DCFF"/>
    <path d="M9.27454 9.53187L9.83938 10.0352L10.5648 9.78875L10.5254 9.02629L10.0159 8.57227C9.66145 8.78527 9.39313 9.12656 9.27454 9.53187Z" fill="#2D4456"/>
    <path d="M11.6284 8.51709L11.1482 9.00397V9.95967H11.9047L12.4341 9.42299C12.2886 9.02939 11.9985 8.70623 11.6284 8.51709Z" fill="#2D4456"/>
    <path d="M11.8401 9.63697C11.6932 9.24518 11.3152 8.96631 10.8721 8.96631C10.4289 8.96631 10.0509 9.24518 9.90398 9.63697C9.86163 9.7499 9.90117 10.6706 10.8721 10.6706C11.843 10.6706 11.8825 9.7499 11.8401 9.63697Z" fill="#FF5876"/>
    <path d="M10.872 10.3079C10.4289 10.3079 10.051 10.029 9.90408 9.63721C9.8617 9.75014 9.83838 9.87236 9.83838 10.0001C9.83838 10.571 10.3012 11.0337 10.872 11.0337C11.4428 11.0337 11.9056 10.5709 11.9056 10.0001C11.9056 9.87236 11.8822 9.75014 11.8399 9.63721C11.693 10.029 11.3151 10.3079 10.872 10.3079Z" fill="#E6485D"/>
    <path d="M11.175 3.65514V2.71658C11.175 2.54924 11.0393 2.41357 10.872 2.41357C10.7046 2.41357 10.569 2.54924 10.569 2.71658V3.65514C10.569 3.82248 10.7046 3.95814 10.872 3.95814C11.0394 3.95814 11.175 3.82248 11.175 3.65514Z" fill="#405B6C"/>
    <path d="M10.569 16.3448V17.2833C10.569 17.4507 10.7046 17.5863 10.872 17.5863C11.0394 17.5863 11.175 17.4507 11.175 17.2833V16.3448C11.175 16.1774 11.0393 16.0417 10.872 16.0417C10.7046 16.0417 10.569 16.1774 10.569 16.3448Z" fill="#405B6C"/>
    <path d="M18.1554 10.303C18.3228 10.303 18.4584 10.1674 18.4584 10C18.4584 9.83269 18.3227 9.69702 18.1554 9.69702H17.2168C17.0495 9.69702 16.9138 9.83269 16.9138 10C16.9138 10.1674 17.0495 10.303 17.2168 10.303H18.1554Z" fill="#405B6C"/>
    <path d="M3.58865 9.69702C3.42127 9.69702 3.28564 9.83269 3.28564 10C3.28564 10.1674 3.42127 10.303 3.58865 10.303H4.52721C4.69459 10.303 4.83021 10.1674 4.83021 10C4.83021 9.83269 4.69455 9.69702 4.52721 9.69702H3.58865Z" fill="#405B6C"/>
  </g>
  <defs>
    <clipPath id="clip0_4098_96">
      <rect width="20" height="20" fill="white" transform="translate(0.87207)"/>
    </clipPath>
  </defs>
</svg>
                                            {!this.state.headerRosterScroll && 
                                                (!this.state.scrolled && <div className='label '>Shifts</div> )
                                            }
                                            
                                    </div>
                                }

                                <OverlayTrigger ref={"OverlayTriggerExport1"} placement="bottom" trigger={"click"} rootClose overlay={
                                    <Tooltip id={`${this.state.headerRosterScroll ? 'header-roster-scroll' : 'der'}`} bsClass="fade in tooltip bottom popup-menu-roster-export">
                                        <CSVLink
                                            data={csvData.data}

                                            headers={csvData.headers}
                                            newLineSeparator={`\r\n`}
                                            filename={this.state.flagTab === 1 ? ('Week' + moment(this.state.arrDay[0]).isoWeek() + '.' + moment(this.state.arrDay[0]).format('YYYY') + '.csv') : moment(this.state.onlyDay).format('DD-MM-YYYY') + '.csv'}
                                            onClick={this.totalhours}>
                                            Export CSV File
                                        </CSVLink>
                                        <PrintButton id={this.state.flagTab === 1 ? "week-view" : "day-view"}
                                            onStartEnd={() => { this.setState({ isLoadingExport: !this.state.isLoadingExport }) }}
                                            headerClass={"header-roster"}
                                            colItem={this.state.flagTab === 1 ? 1 : 2}
                                            nameSave={this.state.flagTab === 1 ? ('Week' + moment(this.state.arrDay[0]).isoWeek() + '.' + moment(this.state.arrDay[0]).format('YYYY')) : moment(this.state.onlyDay).format('DD-MM-YYYY')}
                                            className={"export"}
                                            label={"Export PDF"}
                                            onLogout={this.onLogout}
                                        />
                                    </Tooltip>
                                } >
                                    <div className="display_row align-center btn-new-event button-resp"
                                        style={{ position: 'relative', opacity: (this.state.rosterList && memberList ? 1 : 0.2), cursor: (this.state.rosterList && memberList) ? 'pointer' : 'not-allowed' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M17.5938 1.6001V9.80635H4.3125V1.6001C4.3125 1.42822 4.45312 1.2876 4.625 1.2876H17.2812C17.4531 1.2876 17.5938 1.42822 17.5938 1.6001Z" fill="white"/>
  <path d="M15.875 8.2439C15.875 8.41577 15.7344 8.5564 15.5625 8.5564H6.34375C6.17188 8.5564 6.03125 8.41577 6.03125 8.2439C6.03125 8.0689 6.17188 7.9314 6.34375 7.9314H15.5625C15.7344 7.9314 15.875 8.0689 15.875 8.2439Z" fill="#00A651"/>
  <path d="M17.5938 16.4438V18.4001C17.5938 18.572 17.4531 18.7126 17.2812 18.7126H4.625C4.45312 18.7126 4.3125 18.572 4.3125 18.4001V16.4438H17.5938Z" fill="#F2F2F2"/>
  <path d="M18.8031 10.1189V16.1314C18.8031 16.3064 18.6656 16.4439 18.4906 16.4439H3.41565C3.24065 16.4439 3.10315 16.3064 3.10315 16.1314V10.1189C3.10315 9.9439 3.24065 9.8064 3.41565 9.8064H18.4906C18.6656 9.8064 18.8031 9.9439 18.8031 10.1189Z" fill="#00A651"/>
  <path d="M15.875 3.8689C15.875 4.04077 15.7344 4.1814 15.5625 4.1814H6.34375C6.17188 4.1814 6.03125 4.04077 6.03125 3.8689C6.03125 3.6939 6.17188 3.5564 6.34375 3.5564H15.5625C15.7344 3.5564 15.875 3.6939 15.875 3.8689Z" fill="#00A651"/>
  <path d="M15.875 6.0564C15.875 6.22827 15.7344 6.3689 15.5625 6.3689H6.34375C6.17188 6.3689 6.03125 6.22827 6.03125 6.0564C6.03125 5.8814 6.17188 5.7439 6.34375 5.7439H15.5625C15.7344 5.7439 15.875 5.8814 15.875 6.0564Z" fill="#00A651"/>
  <path d="M9.3532 13.7625C9.25007 14.1437 9.07195 14.4312 8.82507 14.6187C8.57507 14.8062 8.25945 14.8997 7.88445 14.8997C7.41882 14.8997 7.03132 14.7403 6.7282 14.4184C6.43132 14.1028 6.2782 13.6622 6.2782 13.1187C6.2782 12.5403 6.43132 12.084 6.73132 11.7622C7.03445 11.4406 7.43757 11.2747 7.92507 11.2747C8.35632 11.2747 8.70945 11.4059 8.98132 11.6587C9.14382 11.8118 9.2657 12.0306 9.34382 12.3122C9.35007 12.3337 9.34695 12.3559 9.33757 12.3743C9.32507 12.3934 9.30945 12.4059 9.28757 12.4087L8.61882 12.5715C8.59695 12.5747 8.57507 12.5715 8.55945 12.5622C8.5407 12.5497 8.5282 12.534 8.52195 12.5122C8.48757 12.3559 8.4157 12.234 8.3032 12.1434C8.1907 12.0528 8.05632 12.0087 7.8907 12.0087C7.6657 12.0087 7.48757 12.0868 7.34695 12.2497C7.2032 12.4153 7.13132 12.6868 7.13132 13.0684C7.13132 13.4715 7.2032 13.759 7.34382 13.9212C7.48132 14.0868 7.65632 14.165 7.8782 14.165C8.0407 14.165 8.17507 14.115 8.2907 14.0118C8.40632 13.9087 8.49382 13.7434 8.54695 13.515C8.55007 13.4931 8.5657 13.4743 8.58445 13.465C8.6032 13.4525 8.62507 13.4493 8.64695 13.4556L9.3032 13.6653C9.34382 13.6781 9.3657 13.7218 9.3532 13.7625Z" fill="white"/>
  <path d="M12.4939 13.7875C12.4939 13.9938 12.4376 14.1907 12.322 14.3719C12.2064 14.5497 12.0439 14.6875 11.8345 14.775C11.6282 14.8594 11.3751 14.9032 11.072 14.9032C10.6314 14.9032 10.2845 14.7966 10.0439 14.5907C9.80324 14.381 9.65636 14.0779 9.61261 13.6844C9.60949 13.6625 9.61574 13.6407 9.62824 13.625C9.64074 13.6094 9.65949 13.5969 9.68136 13.5969L10.3407 13.5313C10.3845 13.5282 10.4189 13.5563 10.4251 13.5969C10.4595 13.7972 10.5345 13.9469 10.6407 14.0375C10.747 14.1282 10.8939 14.175 11.0814 14.175C11.2782 14.175 11.4282 14.1344 11.522 14.0529C11.6157 13.9782 11.6626 13.8904 11.6626 13.7904C11.6626 13.7279 11.6439 13.681 11.6095 13.6372C11.572 13.5935 11.5001 13.5529 11.4001 13.5154C11.3251 13.4904 11.1439 13.4404 10.8657 13.3716C10.4845 13.2779 10.2251 13.1622 10.0657 13.0216C9.84386 12.8216 9.73136 12.5747 9.73136 12.2904C9.73136 12.1029 9.78449 11.9279 9.89074 11.7654C9.99386 11.6057 10.147 11.481 10.3439 11.3963C10.5345 11.315 10.7657 11.2744 11.0345 11.2744C11.472 11.2744 11.8095 11.3744 12.0376 11.5713C12.2657 11.7682 12.3876 12.0366 12.4001 12.365C12.4001 12.4088 12.3657 12.4463 12.3251 12.4463L11.647 12.4775C11.6064 12.4775 11.572 12.4494 11.5657 12.4119C11.5407 12.2591 11.4876 12.1494 11.4095 12.0869C11.3282 12.0247 11.2001 11.99 11.0282 11.99C10.8501 11.99 10.7095 12.0275 10.6126 12.0963C10.5595 12.1338 10.5345 12.181 10.5345 12.2432C10.5345 12.2997 10.5595 12.3463 10.6095 12.3869C10.6595 12.431 10.8032 12.5025 11.2001 12.5963C11.5345 12.6744 11.7845 12.756 11.9501 12.8432C12.1189 12.9338 12.2532 13.056 12.3501 13.2122C12.447 13.3688 12.4939 13.5625 12.4939 13.7875Z" fill="white"/>
  <path d="M15.8407 11.4375L14.6407 14.7906C14.6282 14.8219 14.6 14.8437 14.5657 14.8437H13.8438C13.8094 14.8437 13.7813 14.8219 13.7688 14.7906L12.5688 11.4375C12.5625 11.4125 12.5657 11.3878 12.5782 11.3659C12.5938 11.344 12.6188 11.3315 12.6438 11.3315H13.3782C13.4125 11.3315 13.4407 11.3534 13.4532 11.3847L14.225 13.6472L14.975 11.3847C14.9844 11.3534 15.0157 11.3315 15.05 11.3315H15.7688C15.7938 11.3315 15.8157 11.344 15.8313 11.3659C15.8469 11.3875 15.85 11.4125 15.8407 11.4375Z" fill="white"/>
</svg>
                                    </div>
                                </OverlayTrigger>   

                                  
                                <OverlayTrigger  ref={ref => { this.overlayTriggerRef = ref; }} placement="bottom" trigger={"click"}  rootClose overlay={<Tooltip id={`${this.state.headerRosterScroll ? 'export-roster-scroll' : 'edit'}`} bsClass="fade in tooltip bottom popup-menu-roster-export">
                                        <CSVLink
                                            data={csvData.data}
                                            headers={csvData.headers}
                                            className={"header-roster"}
                                            newLineSeparator={`\r\n`}
                                            filename={this.state.flagTab === 1 ? ('Week' + moment(this.state.arrDay[0]).isoWeek() + '.' + moment(this.state.arrDay[0]).format('YYYY') + '.csv') : moment(this.state.onlyDay).format('DD-MM-YYYY') + '.csv'}
                                            onClick={() => {
                                                this.onLogout;
                                                this.overlayTriggerRef.handleHide();
                                              }}
                                            >
                                            Export CSV File
                                        </CSVLink>
                                        <PrintButton id={this.state.flagTab === 1 ? "week-view" : "day-view"}
                                            onStartEnd={() => { this.setState({ isLoadingExport: !this.state.isLoadingExport });
                                            this.overlayTriggerRef.handleHide();  }}
                                            headerClass={"header-roster"}
                                            colItem={this.state.flagTab === 1 ? 1 : 2}
                                            nameSave={this.state.flagTab === 1 ? ('Week' + moment(this.state.arrDay[0]).isoWeek() + '.' + moment(this.state.arrDay[0]).format('YYYY')) : moment(this.state.onlyDay).format('DD-MM-YYYY')}
                                            className={"export"}
                                            label={"Export PDF"}
                                            onLogout={this.onLogout}
                                        />
                                    </Tooltip>
                                } >
                                    <div className="display_row1 align-center btn-new-event button-not-resp toolset d-flex gap-2"
                                        style={{ position: 'relative', opacity: (this.state.rosterList && memberList ? 1 : 0.2), cursor: (this.state.rosterList && memberList) ? 'pointer' : 'not-allowed' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M17.5938 1.6001V9.80635H4.3125V1.6001C4.3125 1.42822 4.45312 1.2876 4.625 1.2876H17.2812C17.4531 1.2876 17.5938 1.42822 17.5938 1.6001Z" fill="white"/>
  <path d="M15.875 8.2439C15.875 8.41577 15.7344 8.5564 15.5625 8.5564H6.34375C6.17188 8.5564 6.03125 8.41577 6.03125 8.2439C6.03125 8.0689 6.17188 7.9314 6.34375 7.9314H15.5625C15.7344 7.9314 15.875 8.0689 15.875 8.2439Z" fill="#00A651"/>
  <path d="M17.5938 16.4438V18.4001C17.5938 18.572 17.4531 18.7126 17.2812 18.7126H4.625C4.45312 18.7126 4.3125 18.572 4.3125 18.4001V16.4438H17.5938Z" fill="#F2F2F2"/>
  <path d="M18.8031 10.1189V16.1314C18.8031 16.3064 18.6656 16.4439 18.4906 16.4439H3.41565C3.24065 16.4439 3.10315 16.3064 3.10315 16.1314V10.1189C3.10315 9.9439 3.24065 9.8064 3.41565 9.8064H18.4906C18.6656 9.8064 18.8031 9.9439 18.8031 10.1189Z" fill="#00A651"/>
  <path d="M15.875 3.8689C15.875 4.04077 15.7344 4.1814 15.5625 4.1814H6.34375C6.17188 4.1814 6.03125 4.04077 6.03125 3.8689C6.03125 3.6939 6.17188 3.5564 6.34375 3.5564H15.5625C15.7344 3.5564 15.875 3.6939 15.875 3.8689Z" fill="#00A651"/>
  <path d="M15.875 6.0564C15.875 6.22827 15.7344 6.3689 15.5625 6.3689H6.34375C6.17188 6.3689 6.03125 6.22827 6.03125 6.0564C6.03125 5.8814 6.17188 5.7439 6.34375 5.7439H15.5625C15.7344 5.7439 15.875 5.8814 15.875 6.0564Z" fill="#00A651"/>
  <path d="M9.3532 13.7625C9.25007 14.1437 9.07195 14.4312 8.82507 14.6187C8.57507 14.8062 8.25945 14.8997 7.88445 14.8997C7.41882 14.8997 7.03132 14.7403 6.7282 14.4184C6.43132 14.1028 6.2782 13.6622 6.2782 13.1187C6.2782 12.5403 6.43132 12.084 6.73132 11.7622C7.03445 11.4406 7.43757 11.2747 7.92507 11.2747C8.35632 11.2747 8.70945 11.4059 8.98132 11.6587C9.14382 11.8118 9.2657 12.0306 9.34382 12.3122C9.35007 12.3337 9.34695 12.3559 9.33757 12.3743C9.32507 12.3934 9.30945 12.4059 9.28757 12.4087L8.61882 12.5715C8.59695 12.5747 8.57507 12.5715 8.55945 12.5622C8.5407 12.5497 8.5282 12.534 8.52195 12.5122C8.48757 12.3559 8.4157 12.234 8.3032 12.1434C8.1907 12.0528 8.05632 12.0087 7.8907 12.0087C7.6657 12.0087 7.48757 12.0868 7.34695 12.2497C7.2032 12.4153 7.13132 12.6868 7.13132 13.0684C7.13132 13.4715 7.2032 13.759 7.34382 13.9212C7.48132 14.0868 7.65632 14.165 7.8782 14.165C8.0407 14.165 8.17507 14.115 8.2907 14.0118C8.40632 13.9087 8.49382 13.7434 8.54695 13.515C8.55007 13.4931 8.5657 13.4743 8.58445 13.465C8.6032 13.4525 8.62507 13.4493 8.64695 13.4556L9.3032 13.6653C9.34382 13.6781 9.3657 13.7218 9.3532 13.7625Z" fill="white"/>
  <path d="M12.4939 13.7875C12.4939 13.9938 12.4376 14.1907 12.322 14.3719C12.2064 14.5497 12.0439 14.6875 11.8345 14.775C11.6282 14.8594 11.3751 14.9032 11.072 14.9032C10.6314 14.9032 10.2845 14.7966 10.0439 14.5907C9.80324 14.381 9.65636 14.0779 9.61261 13.6844C9.60949 13.6625 9.61574 13.6407 9.62824 13.625C9.64074 13.6094 9.65949 13.5969 9.68136 13.5969L10.3407 13.5313C10.3845 13.5282 10.4189 13.5563 10.4251 13.5969C10.4595 13.7972 10.5345 13.9469 10.6407 14.0375C10.747 14.1282 10.8939 14.175 11.0814 14.175C11.2782 14.175 11.4282 14.1344 11.522 14.0529C11.6157 13.9782 11.6626 13.8904 11.6626 13.7904C11.6626 13.7279 11.6439 13.681 11.6095 13.6372C11.572 13.5935 11.5001 13.5529 11.4001 13.5154C11.3251 13.4904 11.1439 13.4404 10.8657 13.3716C10.4845 13.2779 10.2251 13.1622 10.0657 13.0216C9.84386 12.8216 9.73136 12.5747 9.73136 12.2904C9.73136 12.1029 9.78449 11.9279 9.89074 11.7654C9.99386 11.6057 10.147 11.481 10.3439 11.3963C10.5345 11.315 10.7657 11.2744 11.0345 11.2744C11.472 11.2744 11.8095 11.3744 12.0376 11.5713C12.2657 11.7682 12.3876 12.0366 12.4001 12.365C12.4001 12.4088 12.3657 12.4463 12.3251 12.4463L11.647 12.4775C11.6064 12.4775 11.572 12.4494 11.5657 12.4119C11.5407 12.2591 11.4876 12.1494 11.4095 12.0869C11.3282 12.0247 11.2001 11.99 11.0282 11.99C10.8501 11.99 10.7095 12.0275 10.6126 12.0963C10.5595 12.1338 10.5345 12.181 10.5345 12.2432C10.5345 12.2997 10.5595 12.3463 10.6095 12.3869C10.6595 12.431 10.8032 12.5025 11.2001 12.5963C11.5345 12.6744 11.7845 12.756 11.9501 12.8432C12.1189 12.9338 12.2532 13.056 12.3501 13.2122C12.447 13.3688 12.4939 13.5625 12.4939 13.7875Z" fill="white"/>
  <path d="M15.8407 11.4375L14.6407 14.7906C14.6282 14.8219 14.6 14.8437 14.5657 14.8437H13.8438C13.8094 14.8437 13.7813 14.8219 13.7688 14.7906L12.5688 11.4375C12.5625 11.4125 12.5657 11.3878 12.5782 11.3659C12.5938 11.344 12.6188 11.3315 12.6438 11.3315H13.3782C13.4125 11.3315 13.4407 11.3534 13.4532 11.3847L14.225 13.6472L14.975 11.3847C14.9844 11.3534 15.0157 11.3315 15.05 11.3315H15.7688C15.7938 11.3315 15.8157 11.344 15.8313 11.3659C15.8469 11.3875 15.85 11.4125 15.8407 11.4375Z" fill="white"/>
</svg>
                                        {!this.state.headerRosterScroll && <div className='label'>Export</div>
    }
                                    </div>
                                </OverlayTrigger>

                              <div onClick={this.handleClockInOut} className={(this.state.isClockEnabled) ? 'toolset d-flex gap-2 align-center btn-new-event active' : 'toolset align-center btn-new-event d-flex gap-2'} id='clock_In'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <g clip-path="url(#clip0_4098_136)">
    <path d="M3.3669 8.27588H18.5393C18.9202 8.27588 19.229 8.58467 19.229 8.96553V19.3104C19.229 19.6912 18.9202 20 18.5393 20H3.3669C2.98604 20 2.67725 19.6912 2.67725 19.3104V8.96553C2.67725 8.58467 2.98604 8.27588 3.3669 8.27588Z" fill="white"/>
    <path d="M15.4359 14.138C15.4359 16.6138 13.4289 18.6208 10.9531 18.6208C8.47734 18.6208 6.47034 16.6138 6.47034 14.138C6.47034 11.6623 8.47734 9.65527 10.9531 9.65527C13.4289 9.65527 15.4359 11.6623 15.4359 14.138Z" fill="#FFB655"/>
    <path d="M5.0911 18.2758C5.0911 18.6567 4.78231 18.9655 4.40145 18.9655C4.02059 18.9655 3.71179 18.6567 3.71179 18.2758C3.71179 17.895 4.02059 17.5862 4.40145 17.5862C4.78231 17.5862 5.0911 17.895 5.0911 18.2758Z" fill="#00BB77"/>
    <path d="M18.1945 18.2758C18.1945 18.6567 17.8857 18.9655 17.5048 18.9655C17.124 18.9655 16.8152 18.6567 16.8152 18.2758C16.8152 17.895 17.124 17.5862 17.5048 17.5862C17.8857 17.5862 18.1945 17.895 18.1945 18.2758Z" fill="#00BB77"/>
    <path d="M18.1945 9.99996C18.1945 10.3808 17.8857 10.6896 17.5048 10.6896C17.124 10.6896 16.8152 10.3808 16.8152 9.99996C16.8152 9.6191 17.124 9.3103 17.5048 9.3103C17.8857 9.3103 18.1945 9.6191 18.1945 9.99996Z" fill="#00BB77"/>
    <path d="M5.0911 9.99996C5.0911 10.3808 4.78231 10.6896 4.40145 10.6896C4.02059 10.6896 3.71179 10.3808 3.71179 9.99996C3.71179 9.6191 4.02059 9.3103 4.40145 9.3103C4.78231 9.3103 5.0911 9.6191 5.0911 9.99996Z" fill="#00BB77"/>
    <path d="M16.8152 0.344828V8.27586H5.09106V0.344828C5.09106 0.154398 5.24546 0 5.43589 0H16.4704C16.6608 0 16.8152 0.154398 16.8152 0.344828Z" fill="#EEEEEE"/>
    <path d="M11.6427 14.1379C11.6427 14.5188 11.3339 14.8276 10.9531 14.8276C10.5722 14.8276 10.2634 14.5188 10.2634 14.1379C10.2634 13.757 10.5722 13.4482 10.9531 13.4482C11.3339 13.4482 11.6427 13.757 11.6427 14.1379Z" fill="#FF6243"/>
    <path d="M10.6083 11.3794H11.2979V13.4484H10.6083V11.3794Z" fill="#787680"/>
    <path d="M8.19446 13.7932H10.2634V14.4829H8.19446V13.7932Z" fill="#787680"/>
    <path d="M10.9531 1.72412H12.3324V2.41378H10.9531V1.72412Z" fill="#00CCB3"/>
    <path d="M13.0221 1.72412H15.7807V2.41378H13.0221V1.72412Z" fill="#00CCB3"/>
    <path d="M10.9531 3.10352H15.7807V3.79317H10.9531V3.10352Z" fill="#00CCB3"/>
    <path d="M6.47034 2.37939C6.47034 1.82711 6.91805 1.37939 7.47034 1.37939H8.22896C8.78124 1.37939 9.22896 1.82711 9.22896 2.37939V3.13802C9.22896 3.6903 8.78124 4.13802 8.22896 4.13802H7.47034C6.91805 4.13802 6.47034 3.6903 6.47034 3.13802V2.37939Z" fill="#00CCB3"/>
    <path d="M10.9532 15.1725C10.3819 15.1725 9.9187 14.7093 9.9187 14.138C9.9187 13.5667 10.3819 13.1035 10.9532 13.1035C11.5245 13.1035 11.9877 13.5667 11.9877 14.138C11.9877 14.7093 11.5245 15.1725 10.9532 15.1725ZM10.9532 13.7932C10.7628 13.7932 10.6084 13.9476 10.6084 14.138C10.6084 14.3284 10.7628 14.4828 10.9532 14.4828C11.1436 14.4828 11.298 14.3284 11.298 14.138C11.298 13.9476 11.1436 13.7932 10.9532 13.7932Z" fill="#FF9811"/>
  </g>
  <defs>
    <clipPath id="clip0_4098_136">
      <rect width="20" height="20" fill="white" transform="translate(0.953125)"/>
    </clipPath>
  </defs>
</svg>
                            {!this.state.headerRosterScroll && <p class="m-0 button-not-resp">Clock In</p>}
                        </div>

                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">View Staff Hours</Tooltip>} >
                                    <div className="display_row align-center btn-new-event button-resp"
                                        onClick={this.createStaffHours}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_4098_157)">
    <path d="M13.7656 9.41399H11.5273V6.5481C11.5273 6.2245 11.265 5.96216 10.9414 5.96216C10.6178 5.96216 10.3555 6.2245 10.3555 6.5481V9.99993C10.3555 10.3235 10.6178 10.5859 10.9414 10.5859H13.7656C14.0892 10.5859 14.3516 10.3235 14.3516 9.99993C14.3516 9.67634 14.0892 9.41399 13.7656 9.41399Z" fill="#265C76"/>
    <path d="M17.3468 3.59469C15.6358 1.88371 13.361 0.941406 10.9414 0.941406C8.52179 0.941406 6.24702 1.88367 4.53609 3.59461C2.96386 5.16684 2.04077 7.21484 1.90136 9.41406H0.585931C0.348939 9.41406 0.135306 9.5568 0.0446027 9.77578C-0.0460613 9.99473 0.00405585 10.2468 0.171634 10.4143L3.30964 13.5524C3.41952 13.6623 3.56855 13.724 3.72394 13.724C3.87933 13.724 4.02839 13.6623 4.13824 13.5524L7.27628 10.4143C7.44386 10.2468 7.49398 9.99477 7.40331 9.77578C7.31261 9.55684 7.09898 9.41406 6.86199 9.41406H5.59679C5.88964 6.72414 8.17484 4.62371 10.9414 4.62371C12.3775 4.62371 13.7275 5.18293 14.743 6.1984C15.7584 7.21383 16.3177 8.56394 16.3177 10C16.3177 12.9645 13.9059 15.3763 10.9414 15.3763C10.0046 15.3763 9.08269 15.1318 8.27648 14.6702C8.26913 14.6655 8.26144 14.6609 8.25324 14.6561C7.97347 14.4946 7.65554 14.4092 7.33378 14.4092C6.67753 14.4092 6.06617 14.762 5.73824 15.3298C5.4923 15.7557 5.42695 16.2519 5.55425 16.727C5.67753 17.1873 5.96745 17.5743 6.37281 17.8216C6.38511 17.83 6.39784 17.838 6.41101 17.8457C7.78245 18.6392 9.34898 19.0586 10.9414 19.0586C13.361 19.0586 15.6359 18.1163 17.3468 16.4054C19.0578 14.6945 20 12.4196 20 10C20 7.58035 19.0577 5.30555 17.3468 3.59469Z" fill="#FF5B5B"/>
    <path d="M13.7656 9.41399H11.5273V6.5481C11.5273 6.2245 11.265 5.96216 10.9414 5.96216V10.5859H13.7656C14.0892 10.5859 14.3516 10.3235 14.3516 9.99993C14.3516 9.67634 14.0892 9.41399 13.7656 9.41399Z" fill="#1D4659"/>
    <path d="M17.3468 3.59469C15.6358 1.88371 13.361 0.941406 10.9414 0.941406V4.62375C12.3775 4.62375 13.7276 5.18297 14.743 6.19844C15.7585 7.21387 16.3177 8.56398 16.3177 10C16.3177 12.9645 13.9059 15.3763 10.9414 15.3763V19.0586C13.3611 19.0586 15.6359 18.1163 17.3468 16.4054C19.0578 14.6945 20 12.4196 20 10C20 7.58035 19.0577 5.30555 17.3468 3.59469Z" fill="#FF0059"/>
  </g>
  <defs>
    <clipPath id="clip0_4098_157">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>
                                    </div>
                                </OverlayTrigger>
                                <div className="display_row align-center btn-new-event button-not-resp toolset d-flex gap-2"
                                    onClick={this.createStaffHours}>
                                   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g clip-path="url(#clip0_4098_157)">
    <path d="M13.7656 9.41399H11.5273V6.5481C11.5273 6.2245 11.265 5.96216 10.9414 5.96216C10.6178 5.96216 10.3555 6.2245 10.3555 6.5481V9.99993C10.3555 10.3235 10.6178 10.5859 10.9414 10.5859H13.7656C14.0892 10.5859 14.3516 10.3235 14.3516 9.99993C14.3516 9.67634 14.0892 9.41399 13.7656 9.41399Z" fill="#265C76"/>
    <path d="M17.3468 3.59469C15.6358 1.88371 13.361 0.941406 10.9414 0.941406C8.52179 0.941406 6.24702 1.88367 4.53609 3.59461C2.96386 5.16684 2.04077 7.21484 1.90136 9.41406H0.585931C0.348939 9.41406 0.135306 9.5568 0.0446027 9.77578C-0.0460613 9.99473 0.00405585 10.2468 0.171634 10.4143L3.30964 13.5524C3.41952 13.6623 3.56855 13.724 3.72394 13.724C3.87933 13.724 4.02839 13.6623 4.13824 13.5524L7.27628 10.4143C7.44386 10.2468 7.49398 9.99477 7.40331 9.77578C7.31261 9.55684 7.09898 9.41406 6.86199 9.41406H5.59679C5.88964 6.72414 8.17484 4.62371 10.9414 4.62371C12.3775 4.62371 13.7275 5.18293 14.743 6.1984C15.7584 7.21383 16.3177 8.56394 16.3177 10C16.3177 12.9645 13.9059 15.3763 10.9414 15.3763C10.0046 15.3763 9.08269 15.1318 8.27648 14.6702C8.26913 14.6655 8.26144 14.6609 8.25324 14.6561C7.97347 14.4946 7.65554 14.4092 7.33378 14.4092C6.67753 14.4092 6.06617 14.762 5.73824 15.3298C5.4923 15.7557 5.42695 16.2519 5.55425 16.727C5.67753 17.1873 5.96745 17.5743 6.37281 17.8216C6.38511 17.83 6.39784 17.838 6.41101 17.8457C7.78245 18.6392 9.34898 19.0586 10.9414 19.0586C13.361 19.0586 15.6359 18.1163 17.3468 16.4054C19.0578 14.6945 20 12.4196 20 10C20 7.58035 19.0577 5.30555 17.3468 3.59469Z" fill="#FF5B5B"/>
    <path d="M13.7656 9.41399H11.5273V6.5481C11.5273 6.2245 11.265 5.96216 10.9414 5.96216V10.5859H13.7656C14.0892 10.5859 14.3516 10.3235 14.3516 9.99993C14.3516 9.67634 14.0892 9.41399 13.7656 9.41399Z" fill="#1D4659"/>
    <path d="M17.3468 3.59469C15.6358 1.88371 13.361 0.941406 10.9414 0.941406V4.62375C12.3775 4.62375 13.7276 5.18297 14.743 6.19844C15.7585 7.21387 16.3177 8.56398 16.3177 10C16.3177 12.9645 13.9059 15.3763 10.9414 15.3763V19.0586C13.3611 19.0586 15.6359 18.1163 17.3468 16.4054C19.0578 14.6945 20 12.4196 20 10C20 7.58035 19.0577 5.30555 17.3468 3.59469Z" fill="#FF0059"/>
  </g>
  <defs>
    <clipPath id="clip0_4098_157">
      <rect width="20" height="20" fill="white"/>
    </clipPath>
  </defs>
</svg>
                                     {!this.state.headerRosterScroll &&<div className='label' id='Staff_History'>Staff History</div>}
                                </div>
                               
                            </div>}
                            {this.state.flagTab !==2 &&
                            <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={<Tooltip id="edit">Create New Roster</Tooltip>} >
                                    <div className="display_row align-center btn-new-event button-resp"
                                        style={{ opacity: (this.state.dataCreateStaff.length > 0 && this.state.flagTab !== 0 ? 1 : 0.2), cursor: this.state.dataCreateStaff.length ? 'pointer' : 'not-allowed' }}
                                        onClick={this.state.flagTab !== 0 ? this.toggleCreateRoster : null} >
                                        <Image src={require('../../assets/images/icon-plus.png')}
                                            className="icon-plus" />
                                    </div>
                                </OverlayTrigger>}
                                {this.state.flagTab !==1 && this.state.flagTab !==0 && 
                                <div className='viewListDiv display_row'>
                                    <label className='viewList'>View :</label>

                                
                            <div className="drop-down-list-user display_row no-hover menu-link"
                                onClick={this.toggleUserList}>
                                <div className="user-name  user-name-title">{(currentUser && currentUser.name) ? currentUser.name.length > 12 ? `${currentUser.name.substring(0, 12)}...`: currentUser.name   : monthdata[0].label}</div>
                                <i className="fa fa-angle-down toggleUser_icon"/>
                                {this.state.toggleUserList && !this.state.dropdownclose && monthdata && monthdata.length>0 && (
                                    <div className="group-user-list-popup shadow-1">
                                        <div onClick={this.toggleUserList}
                                            className="bg-close-popup full_w full_h"
                                            style={{opacity:0, position:'fixed', top:0, left:0, background:'rgba(0,0,0,0.1)', cursor:'pointer'}}/>
                                            
                                        {monthdata.map((key, idx)=>{
                                          let classView = (key.user.id === currentUser.userid);
                                            return(
                                                <div key={idx+'drop-down-item'}
                                                    className={"drop-down-list-user user-list  col-sm-6 "+(classView ? "background" : "")}
                                                    style={{cursor:'pointer'}} onClick={()=>{this.monthValidation(key,false)}}>
                                                        {console.log("background name :",key.label,key.label.length)}
                                                        <div className='user-name'>
                                                        {(key && key.label) ? key.label.length > 12 ? `${key.label.substring(0, 12)}...`: key.label   : (<ReactLoading type={"spinningBubbles"} color={'#31CCBC'} className='spinnLoader' />)}
                                                        </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                                        
                                </div>
                            }
                           
                                <div className="display_row align-center btn-new-event button-not-resp btn-design add_new_roster"
                                    onClick={this.state.flagTab !== 0 ? this.toggleCreateRoster : null}
                                    style={{ opacity: (this.state.dataCreateStaff.length > 0 && this.state.flagTab !== 0 ? 1 : 0.2), cursor: this.state.dataCreateStaff.length ? 'pointer' : 'not-allowed' }}>
                                    
                                    <div className='addNewRosterBtn'>Add New Roster</div>
                              
                                </div>
                        </div>
                     
                        <Modal
                            show={this.state.isRemoveMember}
                            onHide={this.closeModal}
                            className="fix-modal remove-user"
                            >
                            <Modal.Body>            
                                <Image
                                className="logo"
                                src={require("../../assets/images/icon-member.png")}
                                />            
                                <div className="content" style={{ margin: "40px 15px 30px ",fontSize:"18px"}}>
                                Please create atleast one shift before creating the roster             
                                </div>
                            </Modal.Body>
                            </Modal>
                       
                            <Modal show={this.state.isClockedOut} onHide={this.closeClockModal} id="popup-clock-options" className='clock-in-popup'>
                            <Image src={require('../../assets/images/btn-close-popup.png')} className="icon-close" onClick={this.closeClockModal}/>
                                <Modal.Body>  
                                <Image
                                        className="logo remove-member-logo"
                                        src={require("../../assets/images/register/icon-member.png")}
                                    />                      
                                    <div>
                                        <div className="title-large">Change Clock In Status</div>  
                                        <div className="content-roster-model" style={{ margin: `20px 0` }}>Would you like to deactivate clock in entirely, or change the address associated to the active zone?</div>
                                    </div>
                                        <div className="clock_buttonDiv">
                                        <button type="button"
                                            onClick={() => this.toggleClockOut()}
                                            className="login_btn_login btn-linear_clock shadow_popup"
                                            style={{ width: 164, height: 37, margin: "0px 43px 0px 0px" }}
                                        >
                                          DEACTIVATE
                                        </button>
                                        <button type="button"
                                        onClick={() => this.changePopup()}
                                        className="login_btn_login btn-linear-orange_clock shadow_popup"
                                        style={{ width: 164, height: 37,    margin: "0px 0px 0px 0px" }}
                                    >
                                        CHANGE ADDRESS
                                    </button>
                                    </div>
                                    
                                </Modal.Body>
                            </Modal>
                        
                        <Modal show={this.state.isHoliday}
                        onHide={this.closeHolidayModal}
                        className=" holiday-request">
                            <div className="modal-body holiday-body shadow_popup">
                            <div className='header-modal'>
                                
                                <Image
                                            className="logo"
                                            src={require("../../assets/images/Group_icon.png")}
                                            />
                            <div className="title">Holiday Request</div></div>
                            <div className=" modals-body">
                            <div className='profile-id'>
                            <Image className="logo" src={this.state.holidayUserData && (this.state.holidayUserData.user && this.state.holidayUserData.user.avatar) ? this.state.holidayUserData.user.avatar : Config.avaDef} />
                            <div className='profile-name'>
                            <h5>{this.state.holidayUserData && this.state.holidayUserData.user.fullName ? this.capitalizeFirstLetter(this.state.holidayUserData.user.fullName) : 'Loading..'}</h5> 
                            <h6>{this.state.holidayUserData && this.state.holidayUserData.title?this.state.holidayUserData.title:'-'}</h6></div>
                            </div>
                            <div className='all-details'>
                            <div className="profile-details">
                            <h5>Days:</h5> 
                            <h6>{this.state.bookHoliday && this.state.bookHoliday.rosters.days ? this.state.bookHoliday.rosters.days : 0}</h6>
                            </div>
                            <div className="profile-details">
                            <h5>Date:</h5> 
                            <h6>{this.state.bookHoliday && this.state.bookHoliday.rosters.date ? this.state.bookHoliday.rosters.date : 0}</h6>
                            </div>
                            </div>
                            <div className='all-details'>
                            <div className='Already-Scheduled'>
                            <h5>Already Scheduled:</h5> 
                            <h6>{this.state.bookHoliday && this.state.bookHoliday.rosters.alreadySchedhled ? "Yes" : "No"}</h6>
                            </div> 
                            <div className='Already-Scheduled'>
                            
                            <h6 className='error'>{this.state.bookHoliday && this.state.bookHoliday.rosters.alreadySchedhled ? this.state.bookHoliday.rosters.availableroaster : ""}</h6>
                            </div> 
                            </div>
                        </div>
                        <div className="cta-buttons">
                    <Button
                        className="login_btn_login btn-linear shadow_popup accept-btn"
                        style={{ maxHeight: 43 , borderRadius:"25px"}}
                        onClick={ this._removeModal }
                    >
                        REJECT
                    </Button>
                        <Button
                        className="login_btn_login btn-linear-orange shadow_popup reject-btn"
                        style={{ maxHeight: 43 , borderRadius:"25px"}}
                        onClick={this._confirmModal}
                    >
                        CONFIRM
                    </Button>

                    </div>
                        </div>
                    </Modal>         
                       
                            <Modal show={this.state.isBookedHoliday} id="popup-confirm-roster" className="fix-modal">
                                <Modal.Body>                    
                                        <div>
                                        <Image className="logo" src={require('../../assets/images/register/icon-member.png')} style={{ height:"63px"}}/>
                                        <div className="title-large">Confirmed</div>  
                                        <div className="confirm-roster-modal holiday_text" style={{ margin: `20px 130px` , fontWeight: 600 }}>{this.state.holidayUserData && this.state.holidayUserData.user.fullName ? this.capitalizeFirstLetter(this.state.holidayUserData.user.fullName) : 'Unknown'} has been informed of your decision. The roster will now update to reflect the holiday period.</div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        
                            <Modal show={this.state.isholidayRequestProcessed}>
                                <Modal.Body>                    
                                        <div>
                                        <Image className="logo" src={require('../../assets/images/register/icon-member.png')} style={{ height:"63px"}}/>
                                        <div className="title-large">Already Processed</div>  
                                        
                                    </div>
                                </Modal.Body>
                            </Modal>
                        
                        <Modal show={this.state.isRejectedHoliday} id="popup-confirm-roster" className="fix-modal">
                                <Modal.Body>                    
                                        <div>
                                            <Image
                                            className="logo"
                                            src={require("../../assets/images/icon-member.png")}
                                            />
                                        <div className="title-large">Rejected</div>  
                                        <div className="confirm-roster-modal" style={{ margin: `20px 130px` , fontWeight: 600  }}>{this.state.holidayUserData && this.state.holidayUserData.user.fullName ? this.capitalizeFirstLetter(this.state.holidayUserData.user.fullName) : 'Unknown'} has been informed of your decision</div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                       
                        <div ref={this.divRef} className="member-view display_col "
                            style={{
                                // height: 'calc(100% - 122px)',
                                justifyContent: 'flex-start'
                            }}>
                                {this.state.flagTab !== 2 &&
                            <div className={`header-roster ${this.state.headerRosterScroll ? 'scroll-active' : ''}`}>
                                <div className="display_row full_w align-between date-header">
                                    {(this.state.arrDay && this.state.arrDay.length > 0 && this.state.flagTab === 1) &&
                                       <>
                                        <div className="col-sm-3 time-scroll switch-week align-between justify-center display_row">
                                            <i className="fa fa-angle-left" onClick={() => this.changeWeek(-1)} id='fa-left'/>
                                            {(moment(this.state.arrDay[0]).format('MMM Do') + '-' + moment(this.state.arrDay[this.state.arrDay.length - 1]).format('MMM Do')).toUpperCase()}
                                            <i className="fa fa-angle-right" onClick={() => this.changeWeek(1)} id='fa-right'/>
                                        </div>
                                        
                                       </>
                                        
                                     
                                    }
                                    {this.state.flagTab === 0 &&
                                        <div className="col-sm-6 time-scroll switch-week align-between justify-center display_row">
                                            <i className="fa fa-angle-left" onClick={() => this.changeDay(-1)} />
                                            {moment(this.state.onlyDay).format('dddd, MMM Do').toUpperCase()}
                                            <i className="fa fa-angle-right" onClick={() => this.changeDay(1)} />
                                        </div>
                                    }
                                    {this.state.flagTab === 2 &&
                                        <div className="col-sm-6 time-scroll switch-week align-between justify-center display_row">
                                            
                                        </div>
                                    }
                                    <div className="col-sm-3 display_row tab-view" style={{ minWidth: 0 }}>
                                        {!this.state.isloading && dataTab.map((key, idx) => {
                                            return (
                                                <div key={idx} className={"tab-item " + (this.state.flagTab === idx ? 'active' : '')}
                                                    onClick={() => this.changeTab(idx)}>
                                                    {key}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {this.state.arrDay && this.state.arrDay.length > 0 && this.state.flagTab === 1 &&
                                    <div className="date-view display_row" id="date-view" style={{ marginBottom: 7 }}>
                                        <div className="day-view" style={{ cursor: "pointer" }}
                                            onClick={this.resetWeek}>{moment().format('DD/MM/YYYY')}</div>
                                        {this.state.arrDay && this.state.arrDay.map((key, idx) => {
                                            return (
                                                <div key={idx} className="day-view" id='day-Name'>
                                                    <div className={`${moment(key).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY') ? 'todayDate' : ''}`}>
                                                        {moment(key).format('ddd').toUpperCase()}
                                                    </div>
                                                    <div className={`${moment(key).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY') ? 'todayDate' : ''}`}>
                                                        {moment(key).format('MMM-DD').toUpperCase()}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
    }
                            {this.state.flagTab === 2 &&
                                <MonthDisplay arrDay={this.state.arrDay}
                                    ref="MonthDisplay"
                                    user={MemberId}
                                    resultFilter={resultFilter}
                                    memberList={this.state.memberList}
                                    isAdmin={isAdmin && isAdmin.length > 0}
                                    isOwner={isOwner && isOwner.length > 0}
                                    checkLogin={checkLogin}
                                    createShift={this.createShift}
                                    resetWeek={this.resetWeek}
                                    infoRoster={this.toggleInfo}
                                    changeMonth={this.changeMonth}
                                    changeSecond = {this.changeTimeToSecond}
                                    getDataStaffChoose={this.getDataStaffChoose}
                                    onLoadingChange={this.state.isloading}
                                    totaldatachoose = {this.state.dataChoose}
                                    isScrolled={this.state.headerRosterScroll}
                                    changeTab={this.changeTab}
                                    rosterList={this.state.rosterList}
                                    dataFilter={this.dataFilter}
                                    selectedTeamMemberData={this.state.selectedTeamMemberData}
                                
                                    
                                />
                            }
                            {this.state.flagTab === 1 &&
                                <WeekView arrDay={this.state.arrDay}
                                    ref="WeekView"
                                    user={MemberId}
                                    resultFilter={resultFilter}
                                    memberList={this.state.memberList}
                                    isAdmin={isAdmin && isAdmin.length > 0}
                                    isOwner={isOwner && isOwner.length > 0}
                                    checkLogin={checkLogin}
                                    createShift={this.createShift}
                                    resetWeek={this.resetWeek}
                                    infoRoster={this.toggleInfo}
                                    getDataStaffChoose={this.getDataStaffChoose}
                                    onLoadingChange={this.state.isloading}
                                    searchValue={this.state.searchValue}
                                    isScrolled={this.state.headerRosterScroll}
                                 
                                />
                            }
                            {this.state.flagTab === 0 &&
                                <DayView onlyDay={this.state.onlyDay}
                                    ref="DayView"
                                    shiftList={this.state.shiftList}
                                    admin={isAdmin && isAdmin.length > 0}
                                    owner={isOwner && isOwner.length > 0}
                                    memberList={this.state.memberList}
                                    rosterList={this.state.rosterList}
                                    memberId={MemberId}
                                    popupInfo={this.popupInfo}
                                    checkLogin={checkLogin}
                                    changeDay={this.changeDay}
                                    infoRoster={this.toggleInfo}
                                    createRosterStaff={this.toggleCreateRoster}
                                    removeRoster={(id) => this._removeRoster(id, myTeam)}
                                    getDataStaffChoose={this.getDataStaffChoose}
                                />
                            }
                        </div>
                    </div>
                </div>
                {this.state.flagCreate &&
                    <CreateRoster
                        show={this.state.flagCreate}
                        flagTab={this.state.flagTab}
                        shiftList={this.state.shiftList}
                        arrMemberCreate={this.state.arrMemberCreate}
                        memberList={this.state.memberList}
                        dataStaff={this.state.dataCreateStaff}
                        addRoster={this.addRoster}
                        onlyDay={this.state.onlyDay}
                        createShift={this.createShift}
                        handleClose={this.toggleCreateRoster} />
                }
                {this.state.dataInfo &&
                    <InfoRoster
                        show={this.state.dataInfo}
                        shiftList={this.state.shiftList}
                        clockList={this.state.clockList}
                        editRoster={this.editRoster}
                        createShift={this.createShift}
                        handleClose={this.toggleInfo}
                        removeRoster={this._removeRoster}
                        getRosterClock={this._getRosterClock}
                    />
                }
                <ConfirmRoster show={this.state.flagConfirm}
                    handleClose={this.toggleConfirmRoster} />
                <ActivateClock show={this.state.flagActivateClock}
                    handleClose={this.toggleActivateClock}
                    teamActions={this.props.teamActions}
                    idTeam={this.state.idTeam}
                    address={this.state.address}
                    radius={this.state.radius}
                    lat={this.state.lat}
                    lng={this.state.lng}
                    activate={this.state.clockActivateStep}
                    activateClock={this.activateClock}
                  
                />
                    <div>
                        <Modal
                            show={this.state.isCreateNewRosterShift}
                            onHide={this.closeCreateRosterModal}
                            className="remove-user"
                            >
                            <Modal.Body>            
                                <Image
                                className="logo"
                                src={require("../../assets/images/icon-member.png")}
                                />            
                                <div className="content" style={{ margin: "40px 15px 30px ",fontSize:"18px" }}>
                                Please choose at least one staff before creating the roster             
                                </div>
                            </Modal.Body>
                            </Modal>
                     </div>
            </div>
        )
    }
}

class CreateRoster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messageRoster: '',
            slideIndex: 0,
            isSubmit: false,
            isLoading: false,
            isLoadingConfirm: false,
            isLoadingConfirmAndSend: false,
            dataStaffDay: [],
            fromTime: '00:00',
            toTime: '00:00',
            shiftID: "",
            showDataFormDef: false
        };
        this.dataFormDef = [
            {
                type: 'TIME',
                key: "timeStart",
                id: "timeStart",
                class: "rc-time-picker-input",
                validator: 'isRequired',
                def_value: '00:00',
                feedback: false,
                ignoreFormGroup: true,
            },
            {
                type: 'TIME',
                key: "timeEnd",
                id: "timeEnd",
                class: "rc-time-picker-input",
                def_value: '00:00',
                validator: 'isRequired',
                feedback: false,
                ignoreFormGroup: true,
            }
        ];

        //Shift note UI :

        // this.noteFormDef = [
        //     {
        //         type: 'TEXT',
        //         key: "note",
        //         id: "note",
        //         placeholder:"Shift Note",
        //         def_value: '',
        //         // def_value:'TrangHoang',
        //         validator: 'isNull',
        //         feedback: false,
        //         maxLength: 20
        //     }
        // ];
        this.shiftNote = [{
            type: 'TEXT',
            key: "note",
            id: "note",
            placeholder:"Shift Note",
            def_value: this.props.show.note ? this.props.show.note : '',
            validator: 'isNull',
            feedback: false,
            maxLength: 20
        }]
        this.timeAndNoteFormDef = [
            {
                type: 'TIME',
                key: "timeStart",
                id: "timeStart",
                class: "rc-time-picker-input1",
                validator: 'isRequired',
                def_value: '00:00',
                feedback: false,
                ignoreFormGroup: true,
            },
            {
                type: 'TIME',
                key: "timeEnd",
                id: "timeEnd",
                class: "rc-time-picker-input2",
                def_value: '00:00',
                validator: 'isRequired',
                feedback: false,
                ignoreFormGroup: true,
            },

            // def_value:'TrangHoang',
        ];

        this.slideIndex = 0;
    }

    _handleClose = () => {
        if (this.props.handleClose) this.props.handleClose();
    };

    _createShift = () => {
        if (this.props.createShift) this.props.createShift();
    };

    changeTimeToNumber = (time) => {
        let formatTime = ''
        if (typeof (time) === 'object') {
            formatTime = moment(time).format("HH:mm")
        }
        else {
            formatTime = time
        }
        let a = time.split(':');
        a = parseInt(a[0], 10) + a[1] / 60;
        //a = parseInt(a[0],10) + a[1];
        return parseFloat(a);
    };

    _createNewShift = (flag) => {

        let dt = this.refs['CForm'].formValue();


        let params = {
            name: 'CUSTOM',
            color: 'rgb(49, 204, 188)',
            start: this.changeTimeToNumber(dt.timeStart.value),
            end: this.changeTimeToNumber(dt.timeEnd.value)
        };


        Api.put('/shift/add', params)
            .then(response => {
                console.log('shift add response', response);
                if (response.code && response.code == 200) {
                    this.state.shiftID = response.data.id;
                    this.addRoasterDetails(flag);
                } else {
                    this.setState({
                        messageRoster: response.message!=undefined?response.message:'Error',
                        isLoading: false
                    });
                    this.isSubmit = false;
                }
            });
    }

    addRoasterDetails = (flag) => {
        let { dataStaff, shiftList, flagTab } = this.props;
        let dataCreate = [];
        let dt = ''
        let note = ''
        if (this.refs['CForm']) {
            dt = this.refs['CForm'].formValue();
            note = dt.note.value;
        }
        if (flagTab === 1) {
            for (let i in dataStaff) {
                if (dataStaff[i].user && dataStaff[i].user.staff && dataStaff[i].user.staff.id) {
                    let data = {};
                    data.userID = dataStaff[i].user.staff.id;
                    if (this.slideIndex !== shiftList.length) {
                        data.shifts = [{ shiftID: shiftList[this.slideIndex].id, time: moment(dataStaff[i].date).valueOf(), date_string: dataStaff[i].date.split('T')[0] }];
                    } else {
                        data.shifts = [{ shiftID: this.state.shiftID, time: moment(dataStaff[i].date).valueOf(), date_string: dataStaff[i].date.split('T')[0] }];
                    }
                    dataCreate.push(data);
                }
            }
        }
        else if (flagTab === 0) {
            dataCreate = [...this.state.dataStaffDay];
            if(this.state.shiftID){                
                dataCreate.filter(i => i.shifts[0].shiftID =  this.state.shiftID);
            }
            else{
                dataCreate.filter(i => i.shifts[0].shiftID = shiftList[this.slideIndex].id);                
            }
        }
        else if(flagTab === 2){
            
            for(let i in dataStaff){
                
                if(dataStaff[i]&& dataStaff[i].user.user && dataStaff[i].user.user.id){
                    console.log("------setp 2");
                    
                    let data2 = {};
                    data2.userID = dataStaff[i].user.user.id  ;
                     if(this.slideIndex !== shiftList.length){
                        console.log("------setp 3");
                        data2.shifts = [{shiftID : shiftList[this.slideIndex].id , time : moment(dataStaff[i].date).valueOf(), date_string : dataStaff[i].date.split('T')[0]}];
                     }
                     else{
                        console.log("------setp 4");
                        data2.shifts = [{shiftID : this.state.shiftID , time : moment(dataStaff[i].date).valueOf() , date_string : dataStaff[i].date.split('T')[0]}]
                     }
                     dataCreate.push(data2)
                }
                else{
                    let data2 = {};
                    data2.userID = dataStaff[i].user.staff.id  ;
                     if(this.slideIndex !== shiftList.length){
                        console.log("------------- correct");
                        
                        data2.shifts = [{shiftID : shiftList[this.slideIndex].id , time : moment(dataStaff[i].date).valueOf(), date_string : dataStaff[i].date.split('T')[0]}];
                     }
                     else{
                        data2.shifts = [{shiftID : this.state.shiftID , time : moment(dataStaff[i].date).valueOf() , date_string : dataStaff[i].date.split('T')[0]}]
                     }
                     dataCreate.push(data2)

                }
               
            }
        }

        if (this.props.addRoster) {
          
            this.props.addRoster(dataCreate, flag, note, (err, data) => {
                if (err) {
                    this.setState({
                        messageRoster: err.message,
                        isLoading: false
                    });
                    this.isSubmit = false;
                } else if (data) {
                    this.isSubmit = false;
                    this.setState({
                        isLoading: false
                    })
                }
            });
        }
    }

    _addRoster = (flag) => {
        
        let { dataStaff, shiftList, flagTab } = this.props;
        if (this.isSubmit || this.state.isLoading) return;
        this.setState({
            isLoading: true,
        });

        if (flag === 'N') {
            
            this.setState({ isLoadingConfirm: true });
          } else if (flag === 'Y') {
            
            this.setState({ isLoadingConfirmAndSend: true });
        }


        if (this.isSubmit) return;
        this.isSubmit = true;


        if (this.slideIndex === shiftList.length) {

            this._createNewShift(flag);
        } else {
            this.addRoasterDetails(flag);
        }
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        a = a[0] + ':' + second;
        return a;
    };

    addStaffCreate = (key) => {
        let { onlyDay, shiftList } = this.props;
        let dataStaffDay = [...this.state.dataStaffDay];
        if (!dataStaffDay || dataStaffDay.length === 0) {
            let data = {};
            data.userID = key.user.id;
            if (this.slideIndex !== shiftList.length) {
                data.shifts = [{ shiftID: shiftList[this.slideIndex].id, time: moment(onlyDay).valueOf() }];
            } else {
                data.shifts = [{ shiftID: this.state.shiftID, time: moment(onlyDay).valueOf()}];
            }          
            dataStaffDay.push(data);
        }
        else {
            let remove = false;
            for (let i in dataStaffDay) {
                if (dataStaffDay[i].userID === key.user.id) {
                    dataStaffDay.splice(i, 1);
                    remove = true;
                    break;
                }
            }
            if (!remove) {
                let data = {};
                data.userID = key.user.id;
                if (this.slideIndex !== shiftList.length) {
                    data.shifts = [{ shiftID: shiftList[this.slideIndex].id, time: moment(onlyDay).valueOf() }];
                } else {
                    data.shifts = [{ shiftID: this.state.shiftID, time: moment(onlyDay).valueOf()}];
                } 
                dataStaffDay.push(data);
            }
        }
        this.setState({
            dataStaffDay: dataStaffDay
        })
    };

    render() {
        let { dataStaff, shiftList, flagTab, arrMemberCreate, onlyDay } = this.props;
        let dataId = [];
        const showCreateShift = !shiftList || (shiftList && shiftList.length <= 0);

        return (
            <Modal show={this.props.show} onHide={this._handleClose} id="popup-create-roster">
                <Modal.Body>
                    <div className="modal-title" style={{marginBottom:"30px"}}>Create Roster</div>
                    <div className="display_col align-between">
                        {flagTab === 1 && 
                            <div className="drop-staff display_row btn-linear-invitew margin-0-auto"
                                style={{ height: 110, overflowX: 'auto', width: "auto" }}>
                                {dataStaff && dataStaff.map((key, idx) => {
                                    if (dataId.indexOf(key.user.staff.id) !== -1) return null;
                                    dataId.push(key.user.staff.id);
                                    return (
                                        <div className="staff-view" key={"staff-iten" + idx}>
                                            <Image className="avatar-staff" src={(key.user.staff.avatar) ? key.user.staff.avatar : Config.avaDef} style={{ width: '100%', height: '100%' }} />
                                            <span className="name-staff">{key.user.staff.fullName}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        {flagTab === 0 &&
                            <div style={{ textAlign: 'center' }} className='open-sans-regular'>Please choose staffs to create roster of the {moment(onlyDay).format('dddd, MMM Do')}</div>
                        }
                        {flagTab === 0 && 
                            <div className="drop-staff display_row btn-linear-invitew full_w" style={{ height: 105, overflowX: 'auto' }}>
                                {arrMemberCreate && arrMemberCreate.map((key, idx) => {
                                    const isCheck = this.state.dataStaffDay.filter(i => i.userID === key.user.id);
                                    return (
                                        <div className="staff-view" key={"staff-iten" + idx} onClick={() => this.addStaffCreate(key)}>
                                            <Image className="avatar-staff" src={(key.user.avatar) ? key.user.avatar : Config.avaDef}
                                                style={{ width: '100%', height: '100%' }} />
                                            <span className="name-staff">{key.user.fullName}</span>
                                            {isCheck && isCheck.length > 0 && <i className="fa fa-check" />}
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        {!showCreateShift &&
                            <Carousel
                                afterSlide={(slideIndex) => {
                                    this.slideIndex = slideIndex;
                                    if (slideIndex === shiftList.length) {
                                        this.setState({ showDataFormDef: true })
                                    } else {
                                        this.setState({ showDataFormDef: false })
                                    }
                                }}
                                renderCenterLeftControls={({ previousSlide, currentSlide }) => (
                                    currentSlide === 0 ? null : <i className="fa fa-angle-left" onClick={previousSlide} />
                                )}
                                renderCenterRightControls={({ nextSlide, currentSlide }) => (
                                    currentSlide === ((shiftList && shiftList.length + 1) ? shiftList.length : 0) ? null : <i className="fa fa-angle-right" onClick={nextSlide} />
                                )}
                            // onChange={(slideIndex) => {
                            //     this.slideIndex = slideIndex
                            // }}
                            >
                                {shiftList && shiftList.map((key, idx) => {
                                    let off = false;
                                    if (key.name === 'OFF' && key.start === 0 && key.end === 0) {

                                        // if (key.name === 'OFF') {
                                        // off=false;
                                        off = true;
                                    }
                                    return (
                                        <div key={idx} className="display_col">
                                            {off && <span className="name-shift txt-off">OFF</span>}
                                            {!off && <span className="name-shift">{key.name}</span>}
                                            {!off && <span
                                                className="time-shift">{this.changeTimeToSecond(key.start)} - {this.changeTimeToSecond(key.end)}</span>}
                                        </div>
                                    )
                                })}
                                {/* <div  className="display_col">
                                           <span className="name-shift txt-off">OFF</span>
                                             <span className="name-shift">OFF</span>
                                        
                                        </div> */}
                                <div className="display_col">
                                    <span className="name-shift txt-off">
                                        <span className="name-shift" >Manual Input</span>
                                    </span>
                                    {/*<span className="time-shift">*/}
                                    {/*<CForm data={this.dataFormDef} ref="CForm" />*/}
                                    {/*</span>*/}
                                </div>
                            </Carousel>
                        }

                        {this.state.messageRoster !== '' && (
                            <div className="register_error" style={{ width: '100%', bottom: 70, height: 40, textAlign: 'center', alignItems: 'flex-end' }}>
                                {this.state.messageRoster}
                            </div>
                        )}
                        {showCreateShift && (
                            <div className="display_row justify-center flex_wrap" style={{ position: 'relative', width: '100%', alignItems: 'center', justifyContent: 'center', padding: '20px 0' }}>
                                
                                <div className="col-sm-12 col-md-12 open-sans-regular" style={{ fontSize: 18 }}>Click below to create your Custom Shift</div>
                            </div>
                        )}
                        <div>
                            {this.state.showDataFormDef && !showCreateShift && (
                                <span className="time-shift manualInput-time-shift">
                                    <CForm data={this.timeAndNoteFormDef} ref="CForm" />
                                </span>
                            )}
                        </div>
                        {/* {!this.state.showDataFormDef && !showCreateShift &&
                            <span className="name-shift">

                            </span>
                        } */}
                        {!showCreateShift &&
                            <Button className="menu-left-action button-fonts centered-button btn-linear shadow_popup btn-linear-invite" style={{ width: 'auto' }}
                                onClick={() => { this._addRoster('N') }}>
                                {/* onClick={this._addRoster}> */}
                                CONFIRM
                                {this.state.isLoadingConfirm && this.state.isLoading &&
                                    <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                        <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30} />
                                    </div>
                                }
                            </Button>
                        }
                        {!showCreateShift &&
                            <Button className="menu-left-action button-fonts centered-button btn-linear-orange shadow_popup btn-linear-invite" style={{ width: 'auto' }}
                                onClick={() => { this._addRoster('Y') }}>
                                CONFIRM AND SEND NOTIFICATION
                                {this.state.isLoadingConfirmAndSend && this.state.isLoading &&
                                    <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                        <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30} />
                                    </div>
                                }
                            </Button>
                        }
                        {showCreateShift &&
                            <Button className="menu-left-action btn-linear shadow btn-linear-invite" style={{ width: 'auto' }}
                                onClick={this._createShift}>
                                CREATE CUSTOM SHIFT
                            </Button>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

class InfoRoster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messageRoster: '',
            slideIndex: 0,
            isSubmit: false,
            isLoading: false,
            flagEdit: false,
            showDataFormDef: false,
            clocks: this.getClockList() || [],
            clock_in_time: '',
            clock_out_time: '',
            note:'',   

          
        };
        this.slideIndex = 0;
        this.dataFormDef = [
            {
                type: 'TIME',
                key: "timeStart",
                id: "timeStart",
                class: "rc-time-picker-input",
                validator: 'isRequired',
                def_value: '00:00',
                feedback: false,
                ignoreFormGroup: true,
            },
            {
                type: 'TIME',
                key: "timeEnd",
                id: "timeEnd",
                class: "rc-time-picker-input",
                def_value: '00:00',
                validator: 'isRequired',
                feedback: false,
                ignoreFormGroup: true,
            }
        ];
        // this.noteFormDef = [
        //     {
        //         type: 'TEXT',
        //         key: "note",
        //         id: "note",
        //         placeholder:"Shift Note",
        //         def_value: this.props.show.note ? this.props.show.note : '',
        //         // def_value:'TrangHoang',
        //         validator: 'isNull',
        //         feedback: false,
        //         maxLength: 20
        //     }
        // ];
        this.timeAndNoteFormDef = [
            {
                type: 'TIME',
                key: "timeStart",
                id: "timeStart",
                class: "rc-time-picker-input",
                validator: 'isRequired',
                def_value: '00:00',
                feedback: false,
                ignoreFormGroup: true,
            },
            {
                type: 'TIME',
                key: "timeEnd",
                id: "timeEnd",
                class: "rc-time-picker-input",
                def_value: '00:00',
                validator: 'isRequired',
                feedback: false,
                ignoreFormGroup: true,
            },
            // {
            //     type: 'TEXT',
            //     key: "note",
            //     id: "note",
            //     placeholder:"Shift Note",
            //     def_value: this.props.show.note ? this.props.show.note : '',
            //     // def_value:'TrangHoang',
            //     validator: 'isNull',
            //     feedback: false,
            //     maxLength: 20
            // }
        ];

    }

    changeTimeToNumber = (time) => {
        let formatTime = ''
        console.log(time, typeof (time))
        if (typeof (time) === 'object') {
            formatTime = moment(time).format("hh:mm")
        }
        else {
            formatTime = time
        }
        let a = time.split(':');
        a = parseInt(a[0], 10) + a[1] / 60;
        //a = parseInt(a[0],10) + a[1];
        return parseFloat(a);
    };

    _handleClose = () => {
        if (this.props.handleClose) this.props.handleClose();
 
    };

    _createNewShift = () => {

        let dt = this.refs['CForm'].formValue();
        let params = {
            name: 'CUSTOM',
            color: 'transparent',
            start: this.changeTimeToNumber(dt.timeStart.value),
            end: this.changeTimeToNumber(dt.timeEnd.value)
        };

        Api.put('/shift/add', params)
            .then(response => {
                if (response.code && response.code == 200) {
                    this.state.shiftID = response.data.id;
                    this._updateRoaster();
                } else {
                    this.setState({
                        messageRoster: response.data.message,
                        isLoading: false
                    });
                    this.isSubmit = false;
                }
            });
    }

    _editRoster = () => {
        let { shiftList } = this.props;

        if (shiftList.length === this.slideIndex) {
            this._createNewShift()
        }
        this._updateRoaster();
    };

    _updateRoaster = () => {
        let { show, shiftList } = this.props;
        let params = {
            id: show.id
        };

        if (this.slideIndex < shiftList.length && shiftList[this.slideIndex].id === 'OFF') {
            params.isOff = 'Y';
        }
        else {
            params.isOff = 'N';
            if (shiftList.length === this.slideIndex)
                params.shift = this.state.shiftID;
            else
                params.shift = shiftList[this.slideIndex].id;
        }


        //let dt = this.refs['CForm'].formValue();
        // params.note = dt.note.value;

        if (this.props.editRoster) this.props.editRoster(params, (err, data) => {
            this.isSubmit = false;
        });
        else {
            let dt = this.refs['CForm'].formValue();
            params.note = dt.note.value;
        }
    }

    _rosterRemove = () => {
        this.setState({ isUpdateRemoveTeamConfirmed: true });
    };
    

    _removeRoster = () => {
        // if(this.isSubmit) return;
        // this.isSubmit = true;
        let { show } = this.props;

        if (this.props.removeRoster) this.props.removeRoster(show.id, (err, data) => {
            if (err) {
                // Config.alertMess(err);
            } else if (data) {
                // this.isSubmit = false;
                // this._handleClose();
            }
        });
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        a = a[0] + ':' + second;
        return a;
    };

    createShift = () => {
        if (this.props.createShift) this.props.createShift();
    };

    getClockList = () => {
        let { show } = this.props;

        if (this.props.getRosterClock) this.props.getRosterClock(show.id, (err, data) => {
            if (err) {
                // Config.alertMess(err);
            } else if (data) {

                data.forEach((clock) => {
                    //clock.time = new Date(clock.time).toString();
                    let date = new Date(clock.time);
                    //debugger;
                    //let hours = date.getHours();
                    //let minutes = date.getMinutes();
                    // clock.time = `${hours}:${minutes}`;
                    clock.time = date.toLocaleTimeString();
                })

                let clock_in_list = data.filter(c => c.is_clock_in === true);
                let clock_out_list = data.filter(c => c.is_clock_in === false);

                let clock_in_time = clock_in_list.length > 0 ?
                    clock_in_list[0].time_string : "";
                let clock_out_time = clock_out_list.length > 0 ?
                    clock_out_list[clock_out_list.length - 1].time_string : "";

                this.setState({
                    clocks: data,
                    clock_in_time: data[0].clock_in,
                    clock_out_time: data[0].clock_out,
                    note:data[0].note,
                });
            }
        });
    }

    // _onEditRosterClick = () => {
    //     this.setState({ flagEdit: true });
    // };

    render() {
        let { show, shiftList } = this.props;
        let { flagEdit } = this.state;
        const staff = show.staff;
        const shift = show.shift;
        if (!staff) {
            this._handleClose();
        }
        let shiftOff = false;
        let holidayShift = false;
        console.log(shift,"shifts");
        if(show.isOff!=undefined && show.isOff === 'Y'){
            console.log("shiftOff if");
            shiftOff = true;
        }else{
            console.log("shift check else");
            if ((shift.start === 0 && shift.end === 0)) {
                console.log("shiftOff");
                if(shift && shift.slug!==undefined && shift.slug === 'HOLIDAY_SHIFT'){
                    holidayShift = true;
                }
                else{
                    shiftOff = true;
                }
            }
        }
        const showCreateShift = (flagEdit && (!shiftList || (shiftList && shiftList.length <= 0)));
        return (
            <div>
                {!this.state.isUpdateRemoveTeamConfirmed && 
            <Modal show={!!show} onHide={this._handleClose} id="popup-create-roster" className={"modal custom"}>
                <Modal.Body>
                    <div className={'model-popup clock-in-edit'}>
                    <div className="display_row" style={{ alignItems: "baseline" }}>
                        {flagEdit && <div className="display_row align-center back-to-roaster-info pop"
                            //style={{marginTop: 0, float: "right", color: "#31CCBC", fontSize: "15.3px"}}
                            onClick={() => this.setState({ flagEdit: false })}><img
                                src={require('../../assets/images/roster/icon-back-def.png')}
                                alt={""}
                                className="back-img"
                            />Back to Roster
                        </div>}
                        <div className="title" style={{ textAlign: "center" }}>{moment(show.time).format('dddd, MMM Do')}</div>
                        {flagEdit && <div className="back-to-roaster-info" />}
                    </div>
                    <div className="display_col align-between">
                        <div className="display_row" style={{ justifyContent: "space-between" }}>
                            <div className={`drop-staff display_row btn-linear-invitew ${flagEdit ? "margin-0-auto" : ""}`}
                                //style={{height:94, overflowX:'auto'}}
                                style={{ height: '100%', overflowX: 'auto', width: '140px' }}
                            >
                                <div className={`staff-view  ${flagEdit ? "margin-0-auto" : ""} `} >
                                    <Image className="avatar-staff" src={(staff.avatar) ? staff.avatar : Config.avaDef} style={{ width: '100%', height: '100%' }} />
                                    <span className="name-staff">{staff.fullName}</span>
                                </div>
                            </div>
                            {!flagEdit &&
                                <div className="display_row flex_wrap time">
                                    {shiftOff && <span className="name-shift txt-off">OFF</span>}
                                    {holidayShift && <span className="name-shift txt-off">HOLIDAY</span>}
                                    {(!shiftOff && !holidayShift) && <span className="name-shift time-title">Start time</span>}
                                    {(!shiftOff && !holidayShift) && <span className="name-shift time-title">End time</span>}
                                    {(!shiftOff && !holidayShift) && <span className="time-shift time-value">{this.changeTimeToSecond(shift.start)}</span>}
                                    {(!shiftOff && !holidayShift) && <span className="time-shift time-value">{this.changeTimeToSecond(shift.end)}</span>}
                                </div>
                            }
                            {!flagEdit && <div className="" style={{ width: '25%', padding: '5px' }}
                            >
                                {/*{!flagEdit &&*/}
                                {/*<Button className="menu-left-action btn-linear shadow btn-linear-invite"*/}
                                {/*        //style={{width:'auto'}}*/}
                                {/*        style={{height:'45px'}}*/}
                                {/*        onClick={() => this.setState({flagEdit: !flagEdit})}>*/}
                                {/*    EDIT*/}
                                {/*</Button>*/}
                                {/*}*/}
                                {!flagEdit && 
                                    <Button className="menu-left-action button-fonts edit  btn-linear shadow_popup btn-linear-invite info-roster-button" //style={{width:'auto'}
                                        //style={{height: 'auto'}}
                                        style={shift && shift.slug === 'HOLIDAY_SHIFT' ? {display:'none'} : {}}
                                        onClick={() => { this.setState({ flagEdit: true }) }}>
                                        EDIT
                                    </Button>
                                }
                                {!flagEdit &&
                                    <Button className="menu-left-action delete  button-fonts  btn-linear-orange shadow_popup btn-linear-invite info-roster-button"
                                        // style={{borderRadius:'25px'}}
                                        onClick={this._rosterRemove}
                                        >
                                        
                                        DELETE
                                    </Button>
                                }
                            </div>}
                        </div>
                        <Carousel
                            style={{ display: flagEdit && !showCreateShift ? 'block' : 'none', position: flagEdit && !showCreateShift ? 'relative' : 'absolute' }}
                            afterSlide={(slideIndex) => {
                                this.slideIndex = slideIndex;
                                if (slideIndex === shiftList.length) {
                                    this.setState({ showDataFormDef: true })
                                } else {
                                    this.setState({ showDataFormDef: false })
                                }
                            }}
                            renderCenterLeftControls={({ previousSlide, currentSlide }) => (
                                currentSlide === 0 ? null : <i className="fa fa-angle-left" onClick={previousSlide} />
                            )}
                            renderCenterRightControls={({ nextSlide, currentSlide }) => (
                                currentSlide === ((shiftList && shiftList.length + 1) ? shiftList.length : 0) ? null : <i className="fa fa-angle-right" onClick={nextSlide} />
                            )}
                        // onChange={(slideIndex) => {
                        //     this.slideIndex = slideIndex
                        // }}
                        >
                            {shiftList && shiftList.map((key, idx) => {
                                let off = false;
                                if (key.name === 'OFF') {
                                    off = true;
                                }
                                return (
                                    <div key={idx} className="display_col">
                                        {off && <span className="name-shift txt-off">OFF</span>}
                                        {!off && <span className="name-shift">{key.name}</span>}
                                        {!off && <span
                                            className="time-shift">{this.changeTimeToSecond(key.start)} - {this.changeTimeToSecond(key.end)}</span>}
                                    </div>
                                )

                            })}
                            <div className="display_col">

                                <span className="name-shift txt-off">

                                    <span className="name-shift" ref="CForm" >Manual Input</span>
                                </span>
                                {/*<span className="time-shift">*/}
                                {/*<CForm data={this.dataFormDef} ref="CForm" />*/}
                                {/*</span>*/}
                            </div>
                        </Carousel>
                        {/*{!flagEdit &&*/}
                        {/*    <div className="display_row flex_wrap justify-center" style={{margin:'20px 0'}}>*/}
                        {/*        {shiftOff && <span className="name-shift txt-off">OFF</span>}*/}
                        {/*        {!shiftOff && <span className="name-shift col-sm-6 col-md-6">Start time</span>}*/}
                        {/*        {!shiftOff && <span className="name-shift col-sm-6 col-md-6">End time</span>}*/}
                        {/*        {!shiftOff && <span className="time-shift col-sm-6 col-md-6">{this.changeTimeToSecond(shift.start)}</span>}*/}
                        {/*        {!shiftOff && <span className="time-shift col-sm-6 col-md-6">{this.changeTimeToSecond(shift.end)}</span>}*/}
                        {/*    </div>*/}
                        {/*}*/}
                        {/*{!flagEdit &&*/}
                        {/*<Button className="menu-left-action btn-linear shadow btn-linear-invite" style={{width:'auto'}}*/}
                        {/*        onClick={()=>{this.setState({flagEdit: !flagEdit})}}>*/}
                        {/*    EDIT*/}
                        {/*</Button>*/}
                        {/*}*/}
                        {/*{!flagEdit &&*/}
                        {/*<Button className="menu-left-action btn-linear-orange shadow btn-linear-invite" style={{width:'auto'}}*/}
                        {/*        onClick={this._removeRoster}>*/}
                        {/*    DELETE*/}
                        {/*</Button>*/}
                        {/*}*/}
                        {this.state.messageRoster !== '' && (
                            <div className="register_error" style={{ width: '100%', bottom: 150, height: 40, textAlign: 'center', alignItems: 'flex-end' }}>
                                {this.state.messageRoster}
                            </div>
                        )}
                        {!flagEdit && this.state.clocks.length !== 0 &&
                            <div className="info-details">
                                <div className="custom-modal-title">Clock In/Out</div>
                                <div className="display_row" style={{ margin: "10px 0 37px 0" }}>
                                    <div className="name-shift info-text">
                                        Clock In:
                                    </div>
                                    <div className="clock-time info-text">
                                        {
                                            //this.state.clocks[0].time_string
                                            this.state.clock_in_time
                                        }
                                    </div>
                                    <div className="clock-alert info-radius">
                                        {!this.state.clocks[0].clock_in_radius &&
                                        <i className="fa fa-bell" style={{ margin: "0 10px", color: '#EB6965' }}></i>
                                        }
                                       {this.state.clocks[0].clock_in_radius ? '' : 'Clock is not detected within active zone'}
                                           
                                    </div>
                                </div>
                                <div className="display_row" style={{ margin: "10px 0 22px 0" }}>
                                    <div className="name-shift info-text">
                                        Clock Out:
                                    </div>
                                    <div className="clock-time info-text">
                                        {
                                            //this.state.clocks[this.state.clocks.length -1].time_string
                                            this.state.clock_out_time
                                        }
                                    </div>
                                    <div className="clock-alert info-radius">
                                        {!this.state.clocks[0].is_in_radius && this.state.clocks[0].clock_out &&
                                        <i className="fa fa-bell" style={{ margin: "0 10px", color: '#EB6965' }}></i>
                                        }
                                        {this.state.clocks[0].clock_out && !this.state.clocks[0].is_in_radius ? 'Clock is not detected within active zone' : ''}
                                    </div>
                                </div>
                            </div>
                        }
                        {!flagEdit &&
                            <div className="info-details">
                                <div className="custom-modal-title">Shift Note</div>
                        <div className="shift-note-detail">
                            {this.state.note}
                         </div>
                                </div>
                        } 
                        {!flagEdit &&
                            <Button className="menu-left-action button-fonts centered-button btn-linear shadow_popup btn-linear-invite" style={{ width: 'auto' }}
                                onClick={this._handleClose}
                            >
                                CLOSE
                                {this.state.isLoading &&
                                    <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                        <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30} />
                                    </div>
                                }
                            </Button>
                        }
                        {this.state.showDataFormDef && flagEdit && !showCreateShift && (
                            <span className="time-shift">
                                <CForm data={this.timeAndNoteFormDef} ref="CForm" />
                            </span>
                        )}
                        {/* {!this.state.showDataFormDef && flagEdit && !showCreateShift &&
                            <span className="name-shift">
                                <CForm data={this.noteFormDef} ref="CForm" />
                            </span>
                        } */}
                        {flagEdit && !showCreateShift &&
                            <Button className="menu-left-action button-fonts centered-button btn-linear shadow_popup btn-linear-invite" style={{ width: 'auto' }}
                                onClick={this._editRoster}
                            >
                                CONFIRM AND SEND NOTIFICATION
                                {this.state.isLoading &&
                                    <div className="full_w full_h_loading display_row align-center justify-center view-loading">
                                        <ReactLoading type={"spinningBubbles"} color={'#000'} height={30} width={30} />
                                    </div>
                                }
                            </Button>
                        }
                        {/*{!flagEdit &&*/}
                        {/*    <Button className="menu-left-action btn-linear shadow btn-linear-invite" style={{width:'auto'}}*/}
                        {/*            onClick={()=>{this.setState({flagEdit: !flagEdit})}}>*/}
                        {/*        EDIT*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {/*{!flagEdit &&*/}
                        {/*    <Button className="menu-left-action btn-linear-orange shadow btn-linear-invite" style={{width:'auto'}}*/}
                        {/*            onClick={this._removeRoster}>*/}
                        {/*        DELETE*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {showCreateShift && (
                            <div className="display_row justify-center flex_wrap" style={{ position: 'relative', width: '100%', alignItems: 'center', justifyContent: 'center', padding: '20px 0' }}>
                                <div className="col-sm-12 col-md-12" style={{ fontSize: 18 }}>Custom Shift</div>
                                <div className="col-sm-12 col-md-12" style={{ fontSize: 18 }}>Click below to create</div>
                            </div>
                        )}
                        {showCreateShift &&
                            <Button className="menu-left-action btn-linear shadow btn-linear-invite" style={{ width: 'auto' }}
                                onClick={this.createShift}>
                                CREATE CUSTOM SHIFT
                            </Button>
                        }
                     </div>
                   </div>
                </Modal.Body>
               
            </Modal>}
           
            <Modal
            show={this.state.isUpdateRemoveTeamConfirmed}
            onHide={this.closeModal}
            id="popup-change-plan"
            className={"root-popup-change-plan remove-team-member"}
            style={{  maxWidth: "100%", margin: "auto" }}
        >
            
            <Modal.Body>
            <Image
                className="logo remove-member-logo"
                src={require("../../assets/images/register/icon-member.png")}
            />
            <div className="title">Delete Roster</div>
            <div className="title1 remove-admin">Do you want to remove this roster?</div>
            <div className="button_display_row" style={{ padding: "10px 0" }}>
                
                <Button
                className="login_btn_login btn-linear-orange shadow_popup"
                style={{ maxHeight: 50,  margin: "0px 43px 0px 0px", borderRadius:"25px"}}
                onClick={this._handleClose}
                >
                CANCEL
                </Button>
                <Button
                className="login_btn_login btn-linear shadow_popup"
                style={{ maxHeight: 50, margin: "0 5px" , borderRadius:"25px"}}
                onClick={this._removeRoster}
                >
                CONFIRM
                </Button>
            </div>
            </Modal.Body>
        </Modal>
        </div>
        )
    }
}

class DayView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateNow: moment().format(),
            dataChoose: [],
            flagEdit: false,
            isAddRosterMember:false,
        };
        this.arrMemberCreate = null;
    }

    clearDataChoose = () => {
        this.setState({
            dataChoose: []
        })
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        a = a[0] + ':' + second;
        return a;
    };

    _removeRoster = (id) => {
        if (this.props.removeRoster) this.props.removeRoster(id);
    };

    cropName = (name) => {
        if (!name) return '';
        let nameTemp = name.split(' ');
        return nameTemp[0];
    };

    _createRosterStaff = (arrMemberCreate) => {
        if (arrMemberCreate.length === 0) {
            this.setState({
                isAddRosterMember:true,
            })
        }
        else {
            if (this.props.createRosterStaff) this.props.createRosterStaff(arrMemberCreate);
        }
    };

    _infoRoster = (roster) => {
        if (this.state.flagEdit) return;
        if (this.props.infoRoster) this.props.infoRoster(roster);
    };
    closeModal = () => {
        this.setState({
            isAddRosterMember:false,
        })
    };

    render() {
        console.log('=================DAY VIEW=================');
        let { memberList, rosterList, admin, owner, memberId } = this.props;
        let arrMemberCreate = []
       
        let loader = true
        console.log("admin:", admin)
        console.log("owner:", owner)
        console.log("admin || owner", admin || owner)
        let resultFilter = [];
        let staffcheck=[];
        if (rosterList && memberList && memberList.length) {
            let arrTeam = rosterList.slice(0);
            arrTeam.forEach(function (hash) {
                return function (a) {
                    console.log("-------a",a.staff.id)
                    console.log("-------a",a)
                    if (!hash[a.shift ? a.shift.id : 'Y']) {
                        hash[a.shift ? a.shift.id : 'Y'] = { shift: a.shift || 'Y', staff: [], id: a.id, data: a, roster: [], rosterData: [] };
                        if(!staffcheck.includes(a.staff.id)){
                            resultFilter.push(hash[a.shift ? a.shift.id : 'Y']);
                            
                        }
                    }
                    
                    if (a.staff && a.staff.id) {
                        hash[a.shift ? a.shift.id : 'Y'].staff.push(a.staff);
                        hash[a.shift ? a.shift.id : 'Y'].roster.push(a.id);
                        hash[a.shift ? a.shift.id : 'Y'].rosterData.push(a);
                        
                    }
                    console.log("staffcheck",staffcheck.length,staffcheck);
                };
            }(Object.create(null)));
            console.log("resultFilterrrrr",resultFilter)
            resultFilter.sort((i, j) => { return i.shift.start - j.shift.start });
            if (resultFilter && resultFilter.length) {
                loader = false
            }
            if (memberList.length) {
                loader = false
            }
            console.log("--------re",resultFilter,staffcheck)
            arrMemberCreate = [...memberList];
            arrMemberCreate = arrMemberCreate.filter(i => i.status === 'ACTIVE' && ((owner && i.role !== 'OWNER') || (admin && i.role === 'STAFF')));
        }
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', padding: '0 2px 10px 2px', position: 'relative' }}>
                {(admin || owner) && <div className="display_row align-right button-view" style={{ position: 'initial', marginTop: 0 }}>
                    <Button className="menu-left-action btn-linear-orange shadow_popup"
                        onClick={() => this.setState({ flagEdit: !this.state.flagEdit })}
                        style={resultFilter.length > 0 ? {} : {display:'none'}}
                        >{this.state.flagEdit ? "CANCEL" : "EDIT"}</Button>
                    <Button className="btn-linear menu-left-action shadow_popup" onClick={() => this._createRosterStaff(arrMemberCreate)} style={{borderRadius:"25px"}}>ADD NEW</Button>
                </div>
                
                }
                {/* <div className="scroll full_h_1 full_w"  style={{height:'calc(100vh - 320px)'}}></div> */}
                <div className="scroll full_h_1 full_w">
                    <div id="day-view" style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: 100 }}>
                        {loader &&
                            <div className="display_row align-center justify-center" style={{
                                width: '100%',
                                borderRadius: 25,
                                padding: 10,
                                position: 'absolute',
                                height: '100%'
                            }}>
                                <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30} />
                            </div>
                        }
                        {resultFilter && resultFilter.map((key, idx) => {
                            let totalMem = [];
                            for (let user in memberList) {
                                for (let staff in key.staff) {
                                    if ((!memberId || (memberId && memberId === memberList[user].user.id)) && memberList[user] && memberList[user].user && key.staff[staff].id === memberList[user].user.id) {
                                        totalMem.push(key.staff[staff]);
                                        for (let i = 0; i < arrMemberCreate.length; i++) {
                                            if (arrMemberCreate[i].user && arrMemberCreate[i].user.id === key.staff[staff].id) {
                                                arrMemberCreate.splice(i, 1);
                                            }
                                        }
                                    }
                                }
                            }
                          
                            if (totalMem.length <= 0) {
                                console.log("roster not created on this day")
                                return null
                            }
                           
                           
                            return (
                                <div key={idx + 'roster-view'}
                                    className={"col-sm-6 col-md-6 col-sm-12"}
                                    style={{
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        // width:'100%',
                                        padding: 0,
                                        border: 'none',
                                        margin: 0,
                                        position: 'relative'
                                    }}>
                                    <div style={{
                                        width: '100%', textAlign: 'left',
                                        fontSize: 18,
                                        color: '#DDDCDB',
                                        fontFamily: 'open-sans-regular', padding: '0 0 10px'
                                    }}>
                                       {key.shift === 'Y' ? 'OFF' : key.shift.slug === "HOLIDAY_SHIFT" ? "HOLIDAY": (key.shift.name + ' ' + this.changeTimeToSecond(key.shift.start) + ' - ' + this.changeTimeToSecond(key.shift.end))}
                                       
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                                       
                                        {key.staff && key.staff.map((keyChild, idxChild) => {
                                            let isMember = memberList.filter(i => i.user && i.user.id === keyChild.id);
                                            if (isMember.length <= 0) return null;
                                            if(staffcheck.includes(keyChild.id)) return null;
                                            staffcheck.push(keyChild.id);
                                            return (
                                                <div key={"member" + idxChild} onClick={() => this._infoRoster(key.rosterData[idxChild])}
                                                    style={{
                                                        maxWidth: 93,
                                                        maxHeight: 85,
                                                        minWidth: 93,
                                                        minHeight: 85,
                                                        margin: 8,
                                                        position: "relative",
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: 3,
                                                        overflow: 'hidden',
                                                        width: 61,
                                                        height: 55,
                                                        marginLeft: 1,
                                                        borderWidth: 1,
                                                        display: 'flex',
                                                    }}>
                                                    {this.state.flagEdit &&
                                                        <Image src={require('../../assets/images/button_tasks_delete.png')}
                                                            className="icon-close icon-remove"
                                                            style={{ top: 5, right: 5, zIndex: 2 }}
                                                            onClick={() => this._removeRoster(key.roster[idxChild])}
                                                        />
                                                    }
                                                    <img key={"member" + idxChild}
                                                        alt=""
                                                        style={{
                                                            borderRadius: 3, overflow: 'hidden', width: 93, height: 85, objectFit: 'cover',
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            flex: 1
                                                        }}
                                                        src={keyChild.avatar ? keyChild.avatar : ''} />
                                                    <div style={{
                                                        width: 93,
                                                        height: 85,
                                                        position: 'absolute',
                                                        flex: 1,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                                    }}>
                                                    </div>
                                                    <div style={{ fontSize: 12, fontFamily: 'OpenSans-Extrabold', color: 'white', position: 'absolute' }} className='user-name-day'>
                                                        {keyChild.fullName ? this.cropName(keyChild.fullName) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div
                                        style={{
                                            // borderBottomColor: Config.gStyle.color_DDDCDC,
                                            borderBottomWidth: 1,
                                            width: 226,
                                            marginBottom: 20,
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div>
                        <Modal
                            show={this.state.isAddRosterMember}
                            onHide={this.closeModal}
                            className="remove-user"
                            >
                            <Modal.Body>            
                                <Image
                                className="logo"
                                src={require("../../assets/images/icon-member.png")}
                                />            
                                <div className="content" style={{ margin: "40px 15px 30px ",fontSize:"18px"}}>
                                Create new staffs to have the roster            
                                </div>
                            </Modal.Body>
                            </Modal>
                </div>
            </div>
        )
    }
}

class ConfirmRoster extends Component {

    _handleClose = () => {
        if (this.props.handleClose) this.props.handleClose();
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this._handleClose} id="popup-confirm-roster" className='sadow_popup'>
                <Modal.Body>
                    <Image src={require('../../assets/images/btn-close-popup.png')}
                        className="icon-close"
                        onClick={this._handleClose} />
                    <Image className="logo" src={require('../../assets/images/register/icon-member.png')} />
                    <div className="title">Roster Created!</div>
                    <div className="content">Roster has been created</div>
                </Modal.Body>
            </Modal>
        )
    }
}

class WeekView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataChoose: []
        }
    }

    clearDataChoose = () => {
        this.setState({
            dataChoose: []
        })
    };


    onClickShape = (date, user, flagClick, member) => {
        
        console.log("date",date,"user",user,"flagClick",flagClick,"member",member);
        let { isOwner, isAdmin, checkLogin } = this.props;
        if (flagClick) {
            if (this.props.infoRoster) this.props.infoRoster(flagClick);
            return;
        }
        let dataUser = {
            date: date,
            user: user
        };
        let dataChoose = this.state.dataChoose.slice(0);
        let flag = false;
        for (let i in dataChoose) {
            if (dataChoose[i].date === date && dataChoose[i].user && dataChoose[i].user.staff && user && user.staff && dataChoose[i].user.staff.id === user.staff.id) {
                dataChoose.splice(i, 1);
                this.setState({
                    dataChoose: dataChoose
                });
                flag = true;
                break;
            } 
        }
        if (!flag) {
            if (!isOwner && isAdmin && member.role === 'ADMIN' && checkLogin && checkLogin.user && member && member.user && checkLogin.user.id === member.user.id && checkLogin.user.id !== member.user.id) {
                // if (!isOwner && isAdmin && member.role === 'ADMIN' && checkLogin && checkLogin.user && member && member.user && checkLogin.user.id !== member.user.id) {
                Config.popup.show('INFO', [Config.alertMess({ code: 'CREATEROSTERADMIN' })])
            }
            else {
                dataChoose.push(dataUser);
                this.setState({
                    dataChoose: dataChoose
                });
            }
            console.log("datachoose",dataChoose);
            
        }
        if (this.props.getDataStaffChoose) this.props.getDataStaffChoose(dataChoose);
    };

    changeTimeToSecond = (time) => {
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        second = second <= 9 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        a = a[0] + ':' + second;
        return a;
    };

    resetWeek = () => {
        if (this.props.resetWeek) this.props.resetWeek();
    };

  

    render() {
        let { arrDay, resultFilter, memberList, user, onLoadingChange } = this.props;
        let {searchValue} = this.props
      console.log("checking Week Render : ",this.props.isScrolled);
       
        console.log("checking the arrDay : ",arrDay, "resultFilter", resultFilter, "memberList",memberList, "user", user,"onLoadingChange",onLoadingChange);
        return (
            <div className="display_col full_w week-view">
                {!memberList &&
                    <div className="display_row align-center justify-center" style={{ marginTop: 100 }}>
                        <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30} />
                    </div>
                }
                {onLoadingChange && memberList &&
                    <div className="display_row align-center justify-center" style={{ position:'fixed',top: 460,right: 656 }}>
                        <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} height={30} width={30} />
                    </div>
                }
                {/* <div className="roster-view scroll full_h_1" style={{height:'calc(100vh - 320px)'}}> */}
                <div className={`roster-view scroll full_h_1 weekData ${this.props.isScrolled ? 'scroll-active' : ''}`}>
                    <div className="roster-view" id={"week-view"} style={{ marginBottom: 40 }}>
                        {memberList && memberList.map((key, idx) => {
                            let arr = [];
                            if (resultFilter) {
                                arr = resultFilter.filter(i => i.staff && i.staff.id === key.user.id);
                            }
                            if (memberList) {
                                for (let i in memberList) {
                                    console.log('...',memberList);
                                    let a = arr.filter(j => j.staff.id === memberList[i].user.id);
                                    if (a.length === 0) {
                                        arr.push(
                                            {
                                                activities: [],
                                                staff: memberList[i].user
                                            }
                                        )
                                    }
                                }
                            }
                            
                            if (key.status === 'INVITING' || (user && user !== key.user.id)) return null;
                            //if(key.status === 'INVITING' || key.role === 'OWNER' || (user && user!==key.user.id)) return null;

                            let todayDate = moment(new Date()).format('YYYY-MM-DD');
                            
                            let filteredData = resultFilter
                              .map(item => {
                                let todayActivities = item.activities.filter(activity => activity.date_string === todayDate);
                                console.log("todayActivities",todayActivities);
                            
                                if (todayActivities.length > 0 && todayActivities[0].shift ) {
                                  return {
                                    staffId: item.staff.id,
                                    start: todayActivities[0].shift.start,
                                    clockIn: todayActivities[0].clock_in,
                                    clockOut: todayActivities[0].clock_out || '',
                                  };
                                } else {
                                  return null;
                                }
                              })
                              .filter(Boolean);
                            const timenow = moment.utc();   
                  
                  
                            let a= /\w/.test(key.name)
                            let searchUser = searchValue?key.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 && a:true;
                          return ( searchUser ? (
                            (
                                <div className="roster-member-view display_row" key={idx + 'roster-view'}>
                                    <div className="roster-member-col">
                                        <Image src={(key && key.user && key.user.avatar) ? key.user.avatar : Config.avaDef} />
                                        <div className="grey-background" />
                                        <div className="stick-to-bottom">
                                            <div className="bottom-name-view" id='bottom-name'>
                                                <EllipsisText
                                                    text={key.user.fullName.split(" ")[0]}
                                                    length={33}
                                                />
                                                {filteredData.map(data => {
                                                    if (key.user.id === data.staffId) {
                                                        console.log("data=====================",data.clockIn,data.start,data.clockOut);
                                                        console.log(moment.utc(data.start).format('HH:mm'));
                                                        // Convert the number to a string and split it at the decimal point
  let [clockInwhole, clockInfraction] = data.clockIn.toString().split(':');

  // If there's a fractional part, pad it to 2 digits
  let clockInminutes = clockInfraction ? clockInfraction.padEnd(2, '0') : '00';

  let clockInTime=clockInwhole+":"+clockInminutes;


  let startTime=this.changeTimeToSecond(data.start);


console.log("clockInTime",clockInTime,"startTime",startTime);
                                                    // Check if the user is clocked in
                                                    if (data.clockIn && (clockInTime > startTime) && !data.clockOut) {
                                                        return (
                                                        <div key={key.user.id} className='clockin_info'>
                                                            <p style={{ textAlign: 'center', padding: 5, background: '#EC6E68' }}>
                                                            Late Today
                                                            </p>
                                                        </div>
                                                        );
                                                    } else if (data.clockIn && !data.clockOut) {
                                                        return (
                                                        <div key={key.user.id} className='clockin_info'>
                                                            <p style={{ textAlign: 'center', padding: 5, background: '#6AD5E5' }}>
                                                            Working Now
                                                            </p>
                                                        </div>
                                                        );
                                                    }
                                                    // Check if the user is clocked in late today
                                                     
                                                    }
                                                    return null;
                                                })}
                                            </div> 
                                        </div>                                     
                                    </div>
                                    {arrDay && arrDay.map((keyChild, idxChild) => {
                                        let choose = this.state.dataChoose.filter(i => (i.date === keyChild && i.user && i.user.staff && arr[0] && arr[0].staff && i.user.staff.id === arr[0].staff.id));
                                        let className = (choose && choose.length) > 0 ? 'border-def' : '';
                                       
                                        let hasRoster = false;
                                        if (arr.length > 0) {
                                            for (let i in arr[0].activities) {
                                                if (moment(keyChild).startOf('day').valueOf() === moment(arr[0].activities[i].time).startOf('day').valueOf()) {
                                                    hasRoster = arr[0].activities[i];
                                                    break;
                                                }
                                            }
                                        }
                                        
                                        let isHoliday=false;
                                        if(hasRoster && hasRoster.holiday === 'APPROVED'){
                                            isHoliday=true;
                                        }

                                        let off = false;
                                        if (hasRoster && (!hasRoster.shift || hasRoster.isOff === 'Y')) {
                                            
                                            off = true
                                        }
                                        return (
                                            <div key={idxChild} className={"roster-member-col roster-time " + className}
                                            
                                                onClick={() => this.onClickShape(keyChild, arr[0], hasRoster, key)}>
                                                {hasRoster && !off && (hasRoster.holiday !== 'granted') && <div className="name-view time-info" style={{ backgroundColor: hasRoster.color }}>
                                               {!isHoliday && <div className='shittime'>
                                                    <span style={{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }}>
                                                        {this.changeTimeToSecond(hasRoster.shift.start)}
                                                    </span>
                                                    <span style={{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }}>
                                                        {this.changeTimeToSecond(hasRoster.shift.end)}
                                                    </span> 
                                                </div>
                                    }
                                    {isHoliday && <div className='shittime'>
                                        <span style={{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }}>
                                            HOLS
                                        </span>
                                        </div>
                                    }
                                                    {hasRoster.note !== '' &&  (
                                                        <div>
                                                        <span className='noti-shift'>
                                                            <p className='shift-notes' style= {{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }}>Attached Shift Notes</p>
                                                            <Image className="logo-bell" src={require('../roster/fi_1827349.png')} style= {{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }} />                                                   
                                                        </span>
                                                        </div>
                                                    )}                                                                                                       
                                                </div>}
                                                {hasRoster && off && <div className="name-view time-info txt-off"> OFF</div>}
                                                {hasRoster.holiday == 'granted' && <div className="name-view time-info txt-off">HOLS</div>}                                                
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                          ) : (
                            null
                            ))
                        })}
                    </div>
                </div>
            </div>
            
        )
    }
   
}

const MapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        zoom={16}
        center={{ lat: props.lat, lng: props.lng }}
    >
        {props.isMarkerShown &&
            <div>
                <Marker position={{ lat: props.lat, lng: props.lng }}
                    draggable={true}
                    icon={'https://teemie-web-app.s3.eu-west-1.amazonaws.com/Oval.png'}
                    onDragEnd={(e) => props.onMarkerDragEnd(e)}
                    options={
                        { optimized: true }
                    }
                />
                <Circle options={{
                    fillColor: '#31CCBC',
                    strokeColor: "#C7C7C7",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillOpacity: 0.35,
                }} center={{ lat: props.lat, lng: props.lng }}
                    radius={props.radius}
                />
            </div>
        }
    </GoogleMap>
));

class ActivateClock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            address: '',
            radius: 40,
            lat: 0,
            lng: 0,
            apiKey: "AIzaSyDRFmFO9UFDLT3qIwU9tfuAMA5XxUS4Dp4",
            mess:"",
            isClockEnabled: false
        };

        Geocode.setApiKey(this.state.apiKey);
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.address !== this.props.address || prevProps.radius !== this.props.radius) {
        //     this.setState({ address: this.props.address, radius: this.props.radius });
        // }
        if(prevProps.activate !== this.props.activate){
            this.setState({page:this.props.activate,isClockEnabled:false})

        }
    }

    _handleClose = () => {
        
        setTimeout(()=>{
            this.setState({ page: null, radius: 0 });
            if (this.props.handleClose) this.props.handleClose();
            this.setState({page : 0})
        },500)
    };

    onPlaceSelected = async (place) => {
        
        let address = place.formatted_address;
        let lat = place.geometry.location.lat();
        let lng = place.geometry.location.lng();
        await this.setState({ address: address, lat: lat, lng: lng });
        console.log("lat ====>", this.state.lat)
        console.log("lng ====>", this.state.lng)
    }

    onSearch = () => {
        if (this.state.address === "") {
            this.setState(
              {
                mess: "Please select the Location in the Input box."
              },
              () => {
                setTimeout(() => {
                  this.setState({ mess: "" });
                }, 2000); 
              }
            );
          } 
          else {
            this.setState({ page: 2, radius: 0 });
          } 
          console.log("this.state.address",this.state.address) 
    }

    onMarkerDragEnd = (coord) => {
        let lat = coord.latLng.lat();
        let lng = coord.latLng.lng();

        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                const address = response.results[0].formatted_address;
                this.setState({ address: address, lat: lat, lng: lng });
            },
            (error) => {
                console.error(error);
            }
        );
    }

    onChangeSlider = (e) => {
        const newVal = parseFloat(e.target.value);
        this.setState({ radius: newVal });
        this.positionSliderBubble();
        document.getElementById("myinput").oninput = function () {
            var value = (this.value - this.min) / (this.max - this.min) * 100
            this.style.background = 'linear-gradient(to right, #6AD5E5 0%, #00CDAC ' + value + '%, #fff ' + value + '%, white 100%)'
        };
    }

    positionSliderBubble = () => {
        let radius = `${(this.state.radius - 13) * 3.7}px`;
        const ele = document.querySelector('.bubble');
        if (ele) {
            ele.style.left = radius;
        }
        return radius;
    }

    saveClockOptions = () => {
        let params = {
            id: this.props.idTeam,
            is_clock_enabled: true,
            business_clock_in_address: this.state.address,
            business_clock_lat: this.state.lat,
            business_clock_long: this.state.lng,
            business_radius: this.state.radius

        }

        this.props.teamActions.editClockOptions(params, (err, res) => {
            if (err) {
                Config.popup.show('INFO', err.message);
            }else{
                this.props.activateClock();
                this.setState({ isClockEnabled: true });
            }
        });
        setTimeout(() => {
            this.setState({ page: 3 });
        }, 1000);
        
    }

    onactiveClock = () => {
        this.setState({ page: 1 });
    }
    changePage = () =>{
      this.onactiveClock()
    }
    render() {
        return (
            <Modal show={this.props.show} onHide={this._handleClose} id="popup-clock-options" className='clock-in-popup'>
                
              {this.state.page === 2 && (<div className="display_row align-center back-to-roaster-info pop" onClick={this.changePage} style={{margin:"16px 0px 0px 42px"}}><img
                                src={require('../../assets/images/roster/icon-back-def.png')}
                                alt={""}
                                className="back-img"
                            />Back to Roster
                        </div>)}  
                <Image src={require('../../assets/images/btn-close-popup.png')} className="icon-close" onClick={this._handleClose}/>
                <Modal.Body>
                    {this.state.page === 0 ? (
                        <div>
                            <div className="title-large">Confirm Clock in/Out</div>
                            <div className="content-roster-model" style={{ margin: `20px 0` }}> Please confirm that you would like to activate clock in/out? This will mean all staff will need to clock in and out when they are scheduled to work, and will add a column to your exported timesheets to track clocked in hours in addition to scheduled hours.</div>
                            <button type="button"
                                onClick={() => this.onactiveClock()}
                                className="btn-linear shadow_popup clock-button"
                                style={{ width: 240, height: 50, margin: `50px 0 0 0 ` , borderRadius:"25px"}}
                            >
                                NEXT
                            </button>
                        </div>
                    ) :
                    this.state.page === 1 ? (
                        <div>
                            <div className="title-large">Activate Clock In/Out</div>
                            <div className="content-roster-model">To activate this feature, please input your company address. You can then adjust the active zone around your business. Your team will then be able to clock in and out when arriving or leaving work.</div>
                            <div>
                                <Autocomplete
                                    id="foo"
                                    apiKey={this.state.apiKey}
                                    defaultValue={this.state.address}
                                    onPlaceSelected={(place) => {
                                        this.onPlaceSelected(place);
                                    }}
                                    options={{
                                        types: []
                                    }}
                                />
                                 <div className="register_error" style={{width:'100%', bottom:60, textAlign:'center',alignItems:'start' , marginTop: '-20px'}}>
                                    {this.state.mess}
                                    </div>
                            </div>
                            <button type="button"
                                onClick={() => this.onSearch()}
                                className="btn-linear shadow_popup clock-button"
                                style={{ width: 240, height: 50 , borderRadius:"25px"}}
                            >
                                Next
                            </button>
                        </div>
                    ) : (this.state.page === 2 ? (
                        <div>
                          
                            <div className="title-large">Activate Clock In/Out</div>
                            <div className="content-roster-model">To activate this feature, please input your company address. Your team will then be able to clock in and out when arriving or leaving work.</div>
                            <div>
                                {console.log("lat : ", this.state.lat)}
                                {console.log("lng : ", this.state.lng)}
                                <MapComponent
                                    isMarkerShown={true}
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${this.state.apiKey}&libraries=geometry,drawing,places`}
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `200px`, margin: `20px 0` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    lat={this.state.lat}
                                    lng={this.state.lng}
                                    radius={this.state.radius}
                                    onMarkerDragEnd={this.onMarkerDragEnd}
                                />
                                <div className="content">Adjust the slider to increase or decrease the activation zone around your business</div>

                                {/* <RangeStepInput 
                                    min={10} max={200}
                                    value={this.state.radius} step={1}
                                    onChange={e => this.onChangeSlider(e)}
                                /> */}
                            </div>
                            <div className='range_input_map'>
                                <input id="myinput" type="range" min="25" max="450" width={60} height={28} onChange={e => this.onChangeSlider(e)} value={this.state.radius} step={1} />
                            </div>
                            <button type="button"
                                onClick={this.saveClockOptions}
                                className="btn-linear shadow_popup clock-button"
                                style={{ width: 240, height: 50 , borderRadius:"25px"}}
                            >
                                Confirm
                            </button>
                        </div>
                    ) :(this.state.isClockEnabled? (
                        <div>
                        <div className="title-large">Clock In/Out Confirmed</div>
                        <div className="content-roster-model" style={{ margin: `20px 0` }}>Ok great. This feature is now ready and will be in place for your team on their apps right away. They will only be able to clock in or out when they are in the active zone you have created around your business location and their hours will reflect within their roster.</div>
                        <button type="button"
                            onClick={this._handleClose}
                            className="btn-linear shadow_popup clock-button"
                            style={{ width: 240, height: 50, margin: `50px 0 0 0 ` , borderRadius:"25px"}}
                        >
                            CLOSE
                        </button>
                    </div> ):<div>
                        <div className="title-large">Clock In/Out Confirmed</div>
                        <div className="content-roster-model" style={{ margin: `20px 0` }}>Ok great. This feature is now ready and will be in place for your team on their apps right away. They will only be able to clock in or out when they are in the active zone you have created around your business location and their hours will reflect within their roster.</div>
                        <button type="button"
                            onClick={this._handleClose}
                            className="btn-linear shadow_popup clock-button"
                            style={{ width: 240, height: 50, margin: `50px 0 0 0 ` , borderRadius:"25px"}}
                        >
                            CLOSE
                        </button>
                    </div>))}
                </Modal.Body>
            </Modal>
        )
    }
}


class MonthDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMonth: moment(),
            weeks: [],
            flagTab:2,
            dataChoose: [],
            rosterinfo:[],
        
       };
    }

   
  
    componentDidMount=async()=> {
        console.log("componentDidMount data filter",this.props);
        let {dataFilter}=await this.props;
        console.log("dataFilter",dataFilter);
        let timeFrom = moment(this.props.dataFilter.timeFrom);
        console.log("componentDidMount data filter from",timeFrom);
        if(this.props.dataFilter && this.props.dataFilter.timeFrom){
            console.log("componentDidMount data filter from",this.props.dataFilter.timeFrom);
            let timeFrom = moment(this.props.dataFilter.timeFrom);
            this.setState({
                currentMonth: timeFrom
            })
            this.generateCalendar(this.state.currentMonth);
        }
        else{
            this.generateCalendar(this.state.currentMonth);
        }
        console.log("componentDidMount",this.state.currentMonth,this.props);
    }
    
    generateCalendar = (month) => {
        const startOfMonth = month.clone().startOf('month');
        const endOfMonth = month.clone().endOf('month');
        const startOfCalendar = startOfMonth.clone().startOf('week');
        const endOfCalendar = endOfMonth.clone().endOf('week');

        let weeks = [];
        let day = startOfCalendar.clone();
      
        while (day.isBefore(endOfCalendar, 'day')) {
            let daysInWeek = [];
            for (let i = 0; i < 7; i++) {

                if(day.isSameOrAfter(startOfMonth, 'day') && day.isSameOrBefore(endOfMonth,'day')){
                    daysInWeek.push(day.clone());
                }
                else{
                    daysInWeek.push(day.clone())
                }
                day.add(1, 'days');
            }
            weeks.push(daysInWeek);
        }

        this.setState({
            weeks: weeks
        });
    };

    handlePreviousMonth = () => {
        const previousMonth = this.state.currentMonth.clone().subtract(1, 'month');
        this.setState({ currentMonth: previousMonth,dataChoose: [] }, () => {
            this.generateCalendar(this.state.currentMonth);
            console.log("---------------------previousMonth"+previousMonth);
            if (this.props.getDataStaffChoose) this.props.getDataStaffChoose(this.state.dataChoose);
            this.props.changeMonth(previousMonth);
        });
      
    };

    handleNextMonth = () => {
        const nextMonth = this.state.currentMonth.clone().add(1, 'month');
        this.setState({ currentMonth: nextMonth,dataChoose: [] }, () => {
            this.generateCalendar(this.state.currentMonth);
            console.log("---------------------nextMonth"+nextMonth);
            if (this.props.getDataStaffChoose) this.props.getDataStaffChoose(this.state.dataChoose);
            this.props.changeMonth(nextMonth);
        });
      
    };



    changeTimeToSecond = (time) => {
        if(!time){
            return ""
        }
        let a = time.toString().split('.');
        let second = Math.round(parseFloat('0.' + (a[1] || 0)) * 60);
        // second = second <= 3 ? '0' + parseInt(second, 10) : parseInt(second, 10);
        second = second.toString().padStart(2, '0');

        a = a[0] + ':' + second;      
        return a;
    };
    

    changeTab = (idx) => {
        this.props.changeTab(idx);
    };

    clearDataChoose = () => {
        this.setState({
            dataChoose: []
        })
    };
    loadRosterInfo(){
        const users = this.props.selectedTeamMemberData && this.props.selectedTeamMemberData.user ? this.props.selectedTeamMemberData.user : {};
        let aaaa = this.props.rosterList.filter(item=> item.staff && (item.staff.id === users.id));
        let dataChoose = this.state.dataChoose;
            if(aaaa.length >0){
                dataChoose = dataChoose.concat(aaaa)
            }
            this.setState({
                rosterinfo: dataChoose
            });
    };

    componentDidUpdate(prevProps) {
        if ((prevProps.rosterList !== this.props.rosterList) || (prevProps.selectedTeamMemberData!=this.props.selectedTeamMemberData)) {
            console.log("monthdisplay componentDidUpdate",this.props.rosterList,prevProps.rosterList,this.props.totaldatachoose);
            this.loadRosterInfo();
        }
        
    };

    nameLength = (userName)=>{
        let maxLength = 12
        if (userName.length > maxLength) {
            return userName.substring(0, maxLength) + "...";
        } else {
            return userName;
        }

    }
    onClickShape = (date, user,flagClick,member) => {
        console.log("date",date,"flagClick",flagClick,"user",user,"member",member[0]);
        let isCurrentMonth=date.year() === this.state.currentMonth.year() &&
        date.month() === this.state.currentMonth.month();
        if(isCurrentMonth){
        let members = member[0]
        let days = moment(date).format("YYYY-MM-DD")
    
        let isCurrentMonth = date.year() === this.state.currentMonth.year() &&
                             date.month() === this.state.currentMonth.month();
    
        if (isCurrentMonth) {
            let members = member[0];
            let days = moment(date).format("YYYY-MM-DD");
    
            let { isOwner, isAdmin, checkLogin, totaldatachoose } = this.props;
    
            if (flagClick.totalData !== undefined) {
                if (this.props.infoRoster) this.props.infoRoster(flagClick.totalData);
                return;
            }
    
            let activitiess = [];
            let a = this.props.resultFilter.filter(key => key.staff && user && user.staff && key.staff.id === user.staff.id);
            activitiess.push(a);
    
            let dataUser = {
                date: days,
                user: user,
                activities: activitiess[0]
            };
            console.log("rrrrrrrrrr",this.props.resultFilter);
            let dataChoose = this.state.dataChoose.slice(0);
            let flag = false;
    
            for (let i in dataChoose) {
                if (dataChoose[i].date === days &&
                    dataChoose[i].user &&
                    dataChoose[i].activities &&
                    dataChoose[i].activities[0] &&
                    dataChoose[i].activities[0].staff &&
                    user && user.staff &&
                    dataChoose[i].activities[0].staff.id === user.staff.id) {
                    dataChoose.splice(i, 1);
                    this.setState({
                        dataChoose: dataChoose
                    });
                    flag = true;
                    break;
                }else if(dataChoose[i].date === days){
                    dataChoose.splice(i, 1);
                    this.setState({
                        dataChoose: dataChoose
                    });
                    flag = true;
                    break;
                }

            }
          
            if (!flag) {
                if (!isOwner &&
                    isAdmin &&
                    members.role === 'ADMIN' &&
                    checkLogin &&
                    checkLogin.user &&
                    members.user &&
                    checkLogin.user.id === members.user.id &&
                    checkLogin.user.id !== members.user.id) {
                    Config.popup.show('INFO', [Config.alertMess({ code: 'CREATEROSTERADMIN' })]);
                } else {
                    dataChoose.push(dataUser);
                    this.setState({
                        dataChoose: dataChoose
                    });
                }
                console.log("dataChoose",dataChoose);
            }
            if (this.props.getDataStaffChoose) this.props.getDataStaffChoose(dataChoose);
        }
    }
    
    }
    render() {
        
        const { weeks, currentMonth } = this.state;
        const currentMonthName = currentMonth.format('MMMM YYYY');
        const currentMonthName2 = currentMonth.format('MMMM').toUpperCase();
        const lastmonth = currentMonth.clone().subtract(1, 'month').format('MMMM');
        const nextmonth = currentMonth.clone().add(1, 'month').format('MMMM');
        const weeksName = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        let dataTab = ['DAY', 'WEEK', 'MONTH'];
        
        const {totaldatachoose,memberList,rosterList,onLoadingChange} = this.props;
        const currentDate = moment().format("YYYY-MM-DD");
        const tomorrowDate = moment().add(1, 'day').format("YYYY-MM-DD");


    let indexOfNextActiveUser = -1;
        let b=[]
    for (let i = 0; i < memberList.length; i++) {
        if (memberList[i].status === "ACTIVE") {
            indexOfNextActiveUser = i;
            break; 
        }
    }
    
    if (indexOfNextActiveUser !== -1) {
        let nextActivePerson = memberList[indexOfNextActiveUser];
        b.push(nextActivePerson)
    }
    let dataChoose='';
    console.log("rosterCollection",rosterList);
    const users = this.props.selectedTeamMemberData && this.props.selectedTeamMemberData.user ? this.props.selectedTeamMemberData.user : {};
    let aaaa = rosterList.filter(item=> item.staff && ((item.staff.id === users.id)));
    if(aaaa){
        dataChoose = aaaa; 
    }

    console.log("roasterLissssssssss",dataChoose[0]);
        const userProfile = b[0].user.avatar
        
        const avatarSrc = users.avatar ? users.avatar : Config.avaDef
        const userName = users.fullName || b[0].name;
       
        const titles = (totaldatachoose[0] && totaldatachoose[0].role !== "OWNER") ? totaldatachoose[0].title : "";
        const teamName = memberList[0] && memberList[0].team ? memberList[0].team.name :"---"

        const usedId = totaldatachoose[0] && totaldatachoose[0].user ? totaldatachoose[0].user.id : {};
        const TeamId = totaldatachoose[0] && totaldatachoose[0].team ? totaldatachoose[0].team.id : {};

        let totalData=[];
        let rosterarry = [];
        let hasRoster = false;
        let isRosterDeteced=false;
        for (let i = 0; i < this.state.rosterinfo.length; i++) {
             totalData = this.state.rosterinfo[i];
             console.log("totalDatassssssssssss",totalData);
             isRosterDeteced=true;
             console.log("totalData final",totalData.date_string,totalData);
            if (totalData) {
                hasRoster = true;
               
                const rostermonth = totalData.date_string ? totalData.date_string : {};
                const data_s = rostermonth;
                const Time = totalData.shift ? totalData.shift : {
                    end: 0,
                    start: 0,
                    color: 'transparent'
                };
                const endTime = Time.end;
                const startTime = Time.start;
                const color = Time.color;

                let isHoliday=false;
                let isOff=false;

                if (totalData && (!totalData.shift || totalData.isOff === 'Y')) {
                    isOff = true
                }

                                        if(totalData && totalData.holiday === 'APPROVED'){
                                            isHoliday=true;
                                        }
                                        
                                        let exists = rosterarry.find(item => item.data_s === data_s);
                                        console.log("existsssssssssssss",exists);
                                        if (!exists) {
                rosterarry.push({ 'data_s': data_s, 'start': startTime, 'end': endTime, 'color': color,'isholiday':isHoliday,'isOff':isOff,'totalData':totalData,note:totalData.note });
                                        }
                
              
            }
            
        }
        

        if(!hasRoster && !isRosterDeteced){
        for (let i = 0; i < dataChoose.length; i++) {
            totalData = dataChoose[i];
       
           if (totalData) {
               hasRoster = true;
              
               const rostermonth = totalData.date_string ? totalData.date_string : {};
               const data_s = rostermonth;
              
               const Time = totalData.shift ? totalData.shift : {};
               const endTime = Time.end;
               const startTime = Time.start;
               const color = Time.color;

               let isHoliday=false;
                                       if(totalData && totalData.holiday === 'APPROVED'){
                                           isHoliday=true;
                                       }
                                       
                                       let exists = rosterarry.find(item => item.data_s === data_s);
                                       console.log("existsssssssssssss",exists);
                                       if (!exists) {
               rosterarry.push({ 'data_s': data_s, 'start': startTime, 'end': endTime, 'color': color,'isholiday':isHoliday,totalData:totalData,note:totalData.note });
                                       }
               
             
           }
           
       }
    }

    if(totalData.length === 0){
        totalData = users.length ? users[0] : totaldatachoose.length?totaldatachoose[0]:b[0];
    }
    

        let currentFlag=false
        let tomorrowFlag=false
        let offFlag=false;
        let WorkingTodayStatus=[]
        let WorkingTomorrowStatus=[]
        rosterarry.map((item,ind)=>{

            if(item.data_s == currentDate) {
                let curStart = item.start 
                let curend = item.end
                WorkingTodayStatus.push(curStart , curend)
                currentFlag = true
                offFlag = item.isOff
            }
            else if(item.data_s == tomorrowDate) {
                let tomStart = item.start 
                let tomend = item.end
                WorkingTomorrowStatus.push(tomStart , tomend)
                tomorrowFlag = true
                offFlag = item.isOff

              }
            
      })
   
        return (
           <div>
                <div  className='totalMonthDiv'>
                    <div className='time-scroll mr-3 member_profile'>
                        <div className='leftDiv'>
                            {!totaldatachoose[0] && <Image src={userProfile} alt='Loading' className='loginUserImages'/>}
                            {totaldatachoose[0] && <Image src={avatarSrc} alt='Loading' className='loginUserImages'/>}       
                            <p className='loginUser'>{this.nameLength(userName)}</p>
                            <p className='loginuserTitle'>{titles}</p>
                            <p className='loginuserTitle'>{teamName}</p>
                            <p className='rosterdetails'>{currentFlag ? (WorkingTodayStatus[0] === 0 && WorkingTodayStatus[1] === 0 ? offFlag?'Today OFF':'Today Holiday' : `Working Today\n${this.changeTimeToSecond(WorkingTodayStatus[0])} - ${this.changeTimeToSecond(WorkingTodayStatus[1])}`) : (tomorrowFlag ? (WorkingTomorrowStatus[0] === 0 && WorkingTomorrowStatus[1] === 0 ?  offFlag?'Tomorrow OFF':'Tomorrow Holiday' : `Working Tomorrow\n ${this.changeTimeToSecond(WorkingTomorrowStatus[0])} - ${this.changeTimeToSecond(WorkingTomorrowStatus[1])}`): 'Not Working Today')}</p>
                            <div className='UserDetails'>
                            {totaldatachoose[0] && totaldatachoose[0].role !== "OWNER" &&  <Link to={`/messages?type=user&uid=${usedId}&tid=${TeamId}`}><button className='MonthMessageBtn shadow_popup'  style={{borderRadius:"25px"}}>MESSAGE</button></Link> }
                             
                            </div>
                        </div>
                    </div>
                    { rosterarry.length ===0 && this.props.onLoadingChange ?
                        <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} className='spinnLoader' />:''}
                    <div className={`calenderDiv month_view_calender`}>
                        
<div className={`month-header-roster ${this.props.isScrolled ? 'scroll-active' : ''}`}>
                                <div className="display_row full_w align-between date-header">

                                <div className='col-sm-6 time-scroll switch-week  monthchangeicons'>
                                { currentMonthName && this.props.onLoadingChange ?
                        <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} className='spinnLoader' />:''}
                                <i className="fa fa-angle-left monthIcons-left" onClick={this.handlePreviousMonth}/>
                              {currentMonthName}
                                <i className="fa fa-angle-right monthIcons-right" onClick={this.handleNextMonth} />
                            </div>
                                    
                                    <div className="col-sm-3 display_row tab-view" style={{ minWidth: 0 }}>
                                        {dataTab.map((key, idx) => {
                                            return (
                                                <div key={idx} className={"tab-item " + (this.state.flagTab === idx ? 'active' : '')}
                                                    onClick={() => this.changeTab(idx)}>
                                                    {key}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {this.state.arrDay && this.state.arrDay.length > 0 && this.state.flagTab === 1 &&
                                    <div className="date-view display_row" id="date-view" style={{ marginBottom: 7 }}>
                                        <div className="day-view" style={{ cursor: "pointer" }}
                                            onClick={this.resetWeek}>{moment().format('DD/MM/YYYY')}</div>
                                        {this.state.arrDay && this.state.arrDay.map((key, idx) => {
                                            return (
                                                <div key={idx} className="day-view" id='day-Name'>
                                                    <div className={`${moment(key).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY') ? 'todayDate' : ''}`}>
                                                        {moment(key).format('ddd').toUpperCase()}
                                                    </div>
                                                    <div className={`${moment(key).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY') ? 'todayDate' : ''}`}>
                                                        {moment(key).format('MMM-DD').toUpperCase()}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                            
                            {/* week Name ---------- */}
                            {weeks.map((week, index) => (
                            <div className='date-view-content'>
                                <div className='date-view display_row'>
                                {week.map((day, dayIndex) => (
                                    <div className= {`day-view ${day? moment(day).isSame(moment(),'day')? 'today':'':'disabled'}`}>
                                        {weeksName[dayIndex]}<br></br>
                                    {day?day.format('MMMM').toUpperCase().substring(0,3):index?nextmonth.substring(0,3):lastmonth.substring(0,3)} 
                                    {day ? day.format('DD'):''}
                                    </div>
                                ))}
                                </div>
                                <div className='roster-member-view display_row'>
                                {week.map((day, dayIndex) => {
                                     const formattedDate = moment(day, 'ddd MMM DD').format('YYYY-MM-DD');
                                     let res=  rosterarry.filter(i=> i.data_s == formattedDate);
                                     let roasterexists= res.length > 0 ? true : false;
                                     let hasRoster=res.length>0?res[0]:{};
                                     
                                     let choose = this.state.dataChoose.filter(i=> (i.date === moment(day, 'ddd MMM DD').format('YYYY-MM-DD') && i.user && i.user.staff && totalData && totalData.staff && i.user.staff.id === totalData.staff.id))
                                     let className = (choose && choose.length) > 0 ? 'border-def' : '';

                                     let chooseBorder = this.state.dataChoose.filter(i=> (i.date === moment(day, 'ddd MMM DD').format('YYYY-MM-DD') && i.user && i.user.user && totalData && totalData.user && i.user.user.id === totalData.user.id))
                                     let classNameBorder = (chooseBorder && chooseBorder.length) > 0 ? 'border-def' : '';
                                  
                                     return (
                                    <div className={`roster-member-col roster-time ${moment(day).isSame(currentMonth,'month')?"":'disabled'}` + className + classNameBorder} onClick={() => this.onClickShape(day,totalData,hasRoster,totaldatachoose)}>
                                        {hasRoster && hasRoster.isOff && <div className="name-view time-info txt-off"> OFF</div>}
                                        {roasterexists && !hasRoster.isOff &&
                                                  hasRoster.isholiday && <div className='name-view time-info' style={{backgroundColor : res.length >0 ? hasRoster.color :'', color:'white', fontSize : 21 , padding:15 }}><div className='shittime'>
                                                  <span style={{ color: res.length >0?hasRoster.color !== 'transparent' ? 'white' : '#31ccbc':"" }} className='dayNumber'>
                                                      HOLS
                                                  </span>
                                                  </div></div>
                                                }
                                                

{roasterexists && 
                                                  !hasRoster.isholiday && <div key={dayIndex} className={`name-view time-info ${day? moment(day).isSame(moment(),'day')? 'today':'':'disabled'}` + className + classNameBorder} style={{backgroundColor : res.length >0 ? hasRoster.color :'', color:'white', fontSize : 21 , padding:15 }} onClick={() => this.onClickShape(day,totalData,hasRoster,totaldatachoose)}>
                                                     {!roasterexists ? 
                                                    <ReactLoading type={"spinningBubbles"} color={'#31CCBC'} className='spinnLoader' />:(<div className='shittime'>


                                                    <span style={{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }}>
                                                        {hasRoster.start !== undefined ?  hasRoster.start == 0 ? "" : this.changeTimeToSecond(hasRoster.start.toFixed(2)) : ""}
                                                    </span>
                                                    <span style={{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }}>
                                                   {hasRoster.end !== undefined ?  hasRoster.end == 0 ? "" : this.changeTimeToSecond(hasRoster.end.toFixed(2)) : ""}
                                                    </span>


                                                  </div>)
                                                   }
                                                    {hasRoster.note !== '' &&  (
                                                        
                                                        <span className='noti-shift'>
                                                            <p className='shift-notes' style= {{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }}>Attached Shift Notes</p>
                                                            <Image className="logo-bell" src={require('../roster/fi_1827349.png')} style= {{ color: hasRoster.color !== 'transparent' ? 'white' : '#31ccbc' }} />                                                   
                                                        </span>
                                                    )}
                                                  </div>
                                                }
                                    </div>
                                )
                                })}
                                    </div>
                            </div>
                            ))}
                    </div>
                </div>
</div>
               
        );
    }

}

export default connect(state => ({
    checkLogin: state.main.checkLogin,
    teamList: state.team.teamList,
    memberList: state.team.memberList,
    eventList: state.team.eventList,
    shiftList: state.team.shiftList,
}),
    (dispatch) => ({
        userActions: bindActionCreators(userActions, dispatch),
        companyActions: bindActionCreators(companyActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        eventActions: bindActionCreators(eventActions, dispatch),
        shiftActions: bindActionCreators(shiftActions, dispatch),
        rosterActions: bindActionCreators(rosterActions, dispatch)
    })
)(Roster);
